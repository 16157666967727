import React from "react";
import Title from "../Text/Title";
import {Button} from "@mui/material";

interface CommonButtonProps {
    src: string;
    alt: string;
    text: string;
    onClick: () => void;
    customSx?: React.CSSProperties
}

export default function CommonButton({src, alt, text, onClick, customSx}: CommonButtonProps) : React.JSX.Element {
    return (
        <Button
            variant="outlined"
            fullWidth={true}
            onClick={onClick}
            sx={{
                border: '1px solid #000000',
                color: '#000000',
                borderRadius: '6px',
                padding: '10px 16px 10px 16px',
                textTransform: 'none',
                ...customSx
            }}
        >
            <img style={{width: '16px'}} alt={alt} src={src}/>
            <Title customSx={{marginLeft: '10px', fontSize: '11px'}} text={text}/>
        </Button>
    );
}