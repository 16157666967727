import {useState} from "react";
import phone from "../../asset/etape-1.png";
import scan from "../../asset/etape-2.png";
import PageConfirm from "../pvid/PageConfirm";
import {useTranslation} from "react-i18next";

export default function Classic() {
    const {t} = useTranslation()
    const [openDrawer_DA, setOpenDrawer_DA] = useState(true)
    const [confirm, setConfirm] = useState(false)
    const imageWithWidth = [{width: "20%", img: phone}, {width: "40%", img: scan}]
    const textConfirm = [t("document_Confirm1"), t("document_Confirm2")]
    const textImage = [t("step-1"), t("step-2-classique")]
    return (
        <>
            <PageConfirm onSuccessRouteTo={'/classic/document-selection'} setClickConfirm={setConfirm}
                         clickConfirm={confirm} imgAndWidthArray={imageWithWidth} imgArrayText={textImage}
                         setDrawer={setOpenDrawer_DA} openDrawer={openDrawer_DA} textForConfirm={textConfirm}/>
        </>
    )
}
