import PageConfirm from "./PageConfirm";
import {useState} from "react";
import phone from "../../asset/etape-1.png"
import scan from "../../asset/etape-2.png"
import faceScan from "../../asset/etape-3.png"
import {useTranslation} from "react-i18next";

export default function PVID_process() {
    const {t} = useTranslation()
    const [openDrawer, setopenDrawer] = useState(true)
    const [confirm, setConfirm] = useState(false)
    const imageWithWidth = [{width: "20%", img: phone}, {width: "40%", img: scan}, {width: "40%", img: faceScan}]
    const textImage = [t('step-1'), t('step-2'), t("step-3")]
    const textConfirm = [ t('document_Confirm1'),t('document_Confirm2'),t('document_Confirm3')]
    return (
        <>
            <PageConfirm onSuccessRouteTo={'/shareid-onboarding-qrcode'} setClickConfirm={setConfirm}
                         clickConfirm={confirm} imgAndWidthArray={imageWithWidth} imgArrayText={textImage}
                         setDrawer={setopenDrawer} openDrawer={openDrawer} textForConfirm={textConfirm}/>
        </>
    )
}