import React from "react";
import Template from "../../component/Layout/Template";
import {Box, Button, CircularProgress} from "@mui/material";
import Title from "../../component/Text/Title";
import {useTranslation} from "react-i18next";
import Subtitle from "../../component/Text/Subtitle";
import {useGlobalContext} from "../../component/AppContext";
import ContainedButton from "../../component/Button/ContainedButton";
import OutlinedButton from "../../component/Button/OutlinedButton";
import CommonImage from "../../component/Photo/CommonImage";
import refuse from "../../asset/refuse.png";
import { useNavigate } from "react-router-dom";

export default function Refuse() {
    const { t } = useTranslation();
    const { primaryColor} = useGlobalContext();
    const navigate = useNavigate();

    const handleClick = () => {
        sessionStorage.removeItem('selectedFrontFileName');
        sessionStorage.removeItem('selectedBackFileName');
        sessionStorage.removeItem('myFrontFile');
        sessionStorage.removeItem('myBackFile');
        if (window.location.href.includes('/classic-selfie')) {
            navigate('/classic-selfie/document-selection');
        } else {
            navigate('/classic/document-selection');
        }

    };

    return (
        <Template child={
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                minHeight: '100%',
                // position: 'relative'
            }}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                    <CommonImage src={refuse} alt={refuse} style={{width: '270.94px', marginBottom: '30px'}} />
                    <Title text={t('validate-status', {status: 'refusée'})} />
                    <Subtitle customSx={{marginTop: '10px', marginBottom: '3px', color: '#525050'}} text={t('validate-description', {status: 'refusée'})} />
                    <Subtitle customSx={{marginBottom: '10px', color: '#525050'}} text={t('validate-subtitle-refuse')} />
                </Box>
                <OutlinedButton customSx={{ marginBottom: '20px' }} text={t('change-document')} onClick={handleClick} />
            </Box>
        }/>
    );
}