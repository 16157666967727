import {Box, Button, Modal, Stack} from "@mui/material";
import {styleCard, } from "./VerifyPhoneNumber";
import {btnStyleOutLine} from "../../component/ButtonStyleComponent";

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function FailProcess() {
    const [open, setOpen] = useState(true);
    const {t} = useTranslation()
    const router = useNavigate()
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleCard}>
                    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} rowGap={1} textAlign={'center'}>
                        <span className={'bigText'} style={{fontSize:'18px'}}>{t("errorOtp1")}</span>
                        <span
                            className={'normalText'}>{t("errorOtp2")}</span>
                        <Button variant={'outlined'} sx={btnStyleOutLine} fullWidth={true} style={{marginTop:'5%'}} onClick={() =>{
                            router('/page-block')
                        }}>{t("errorOtp3")}</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}