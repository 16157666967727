import {CSSProperties, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Backdrop, Button, Checkbox, Drawer, Modal, Stack} from "@mui/material";
import certigna from "../../asset/logo.png";
import {btnStyleContain} from "../../component/ButtonStyleComponent";
import {useGlobalContext} from "../../component/AppContext";
import * as React from "react";
import Box from "@mui/material/Box";
import "../pvid/verification.css"
import {getUserPersonalInformation} from "../../service/userInfoApi";
import {uploadUserConsent} from "../../service/createProject";


interface DrawerProps {
    open: boolean,
    setDrawer: Dispatch<SetStateAction<boolean>>,
    textArray: any[],
}

export default function DrawerCheckList({setDrawer, textArray, open}: DrawerProps) {
    const [booleanArray, setBoolArray] = useState(Array.from({length: textArray.length}, () => false))
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Backdrop open={open}>
                <DrawerPageOneMobileV2 setDrawer={setDrawer} textArray={textArray} booleanArray={booleanArray}
                                       setBoolArray={setBoolArray} open={open}/>
            </Backdrop>

        </>
    )
}

interface Props {
    open: boolean,
    setDrawer: Dispatch<SetStateAction<boolean>>,
    textArray: string[],
    booleanArray: boolean[],
    setBoolArray: Dispatch<SetStateAction<boolean[]>>
}

export const ModelFirstPagePC = ({textArray, setDrawer, open, booleanArray, setBoolArray}: Props) => {
    const {t} = useTranslation()

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}
                           rowGap={5}>
                        <img alt="logo" src={certigna} width={'50%'}/>
                        <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'} rowGap={4}>
                            <BodyText/>
                            <ListCheckBox>
                                {booleanArray.map((value, index) => {
                                    return (
                                        <>
                                            <CheckBoxWithText setBoolArray={setBoolArray} textArray={textArray}
                                                              booleanArray={booleanArray} index={index}/>
                                        </>
                                    )
                                })}
                            </ListCheckBox>
                            <Button variant={'contained'} sx={btnStyleContain} fullWidth={true}
                                    disabled={!booleanArray.every(Boolean)}
                                    onClick={() => {
                                    }}>{t('valider')}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}
const BodyText = () => {
    const {t} = useTranslation()
    return (
        <>
            <div style={styleText} className={'footerText'}>
                <span>{t("popup-text-1")} </span>
                <span>{t("popup-text-2")}</span>
                <span> {t("popup-text-3")}</span>
            </div>
        </>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    padding: '50px',
    width: '33%',
    borderRadius: '12px',
    maxWidth: '549px',
    minWidth: '500px'
};
const styleText: CSSProperties = {
    textAlign: 'center',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '26px',
    color: 'black',
    gap: '2px',
    width: '90%'
}

export function DrawerPageOneMobileV2({textArray, setDrawer, open, booleanArray, setBoolArray}: Props) {
    const {t} = useTranslation();
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    useEffect(() => {
        function handleResize() {
            setScreenHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function smallScreenHeight() {
        return screenHeight < 750;
    }

    return (
        <>
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    }
                }}
                variant="persistent"
                anchor="bottom"
                open={open}
            >
                <Stack width={'100%'} height={'100%'} direction={'column'} justifyContent={'space-between'}
                       alignItems={'center'}
                    // paddingY={5}
                       paddingY={smallScreenHeight() ? 4 : 5}
                    // rowGap={3}
                       rowGap={smallScreenHeight() ? 2 : 3}
                       paddingX={'25px'}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        // rowGap={4}
                        rowGap={smallScreenHeight() ? 2 : 4}
                    >

                        <div className={'flexColumn_jc_ac titleDrawer subtitleTitle'}>
                            <span>{t("popup-text-1")}</span>
                            <span>{t("popup-text-2")}</span>
                            <span> {t("popup-text-3")}</span>
                        </div>
                        <ListCheckBox>
                            {booleanArray.map((value, index) => {
                                return (

                                    <CheckBoxWithText setBoolArray={setBoolArray} textArray={textArray}
                                                      booleanArray={booleanArray} index={index} key={index}/>

                                )
                            })}
                        </ListCheckBox>
                    </Stack>
                    <div style={{width: '100%',}}>
                        <Button variant={'contained'} sx={btnStyleContain} fullWidth={true}
                                disabled={!booleanArray.every(Boolean)}
                                onClick={async () => {
                                    const userInformation = await getUserPersonalInformation();
                                    const obj = {
                                        flowId: localStorage.getItem("flowId"),
                                        dossierId: localStorage.getItem("caseID"),
                                        consent: {
                                            ipAddress: userInformation.ip,
                                            consent1: booleanArray[0] === undefined ? false : booleanArray[0],
                                            consent2: booleanArray[1] === undefined ? false : booleanArray[1],
                                            consent3: booleanArray[2] === undefined ? false : booleanArray[2],
                                        }
                                    }
                                    await uploadUserConsent(obj).then(() => setDrawer(false)).catch((v) => {
                                        console.log(v)
                                    })
                                }}>
                            <span className={'btnTextInDrawer'}>
                                 {t('valider')}
                            </span>
                        </Button>
                    </div>
                </Stack>
            </Drawer>


        </>
    )
}

const ListCheckBox = (props: PropsWithChildren) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setScreenHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function smallScreenHeight() {
        return screenHeight < 750;
    }

    return (
        <>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}
                // rowGap={2}
                   rowGap={smallScreenHeight() ? 1 : 2}
                   width={'100%'}>
                {props.children}
            </Stack>
        </>
    )
}

interface CheckBoxProps {
    textArray: any[],
    booleanArray: boolean[],
    setBoolArray: Dispatch<SetStateAction<boolean[]>>
    index: number
}

const CheckBoxWithText = ({setBoolArray, booleanArray, index, textArray}: CheckBoxProps) => {
    const [isCheck, setCheck] = useState(false)
    const {primaryColor} = useGlobalContext()
    const borderPrimary = '1px solid ' + changeAlpha(primaryColor, 0.2)
    const handleChange = (index: any, value: any) => {
        setBoolArray(prevBoolArray => {
            const newBoolArray = [...prevBoolArray];
            newBoolArray[index] = value;
            return newBoolArray;
        });
        setCheck(value)
    }
    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                width={'100%'}
                padding={1}
                style={{
                    border: isCheck ? borderPrimary : '1px solid lightgray',

                    borderRadius: '8px'
                }}
            >
                <Checkbox
                    checked={booleanArray[index]}
                    onChange={(event) => {
                        handleChange(index, event.target.checked);
                    }} sx={{
                    color: 'lightgray',
                    '&.Mui-checked': {
                        color: 'rgba(214, 5, 106, 1)',
                    },
                }}
                />
                <span className={'minContentText'}>{textArray[index]}</span>
            </Stack>
        </>
    )
}

function changeAlpha(rgbaString: string, alpha: number): string {
    // Split the string into an array of values
    const values = rgbaString.substring(5, rgbaString.length - 1).split(",");

    // Modify the alpha value
    values[3] = alpha.toString();

    // Return the modified string
    return `rgba(${values.join(",")})`;
}