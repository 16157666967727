
import ShareIDProcess from "./shareIDProcess";
import {PropsWithChildren, useEffect, useState} from "react";
import {generateRandomString, getPersonValue} from "../../service/createProject";
import {useGlobalContext} from "../../component/AppContext";
import ProjectToastContainer from "../../component/ProjectToastContainer";
import {Stack} from "@mui/material";
import certigna from "../../asset/logo.png";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function ShareIDFrom(){

    const token = localStorage.getItem('sharedid') ?? ""
    const [externalID,setExternal]=useState("")
    const {shouldFetch,personValue}= useGlobalContext()
    const router = useNavigate()
    useEffect(() =>{
            setExternal(localStorage.getItem('caseID')+ "---"+ generateRandomString())
        if(!localStorage.getItem('caseID')){
            router('/')
        }
        getPersonValue().then((value) =>{
            if (value.status !== "VERIFIED"){
                router("/")
            }
        })

    },[shouldFetch])
    function documentCase(name:string) {
        if (name === "PVID"){
                return "all"
        }else if (name === "AUTH_DOCUMENT"){
                return "document"
        }
    }
    return(
        <>

            { (token!=='' && externalID!=='') &&

              <ContainerShareID>
                  <ShareIDProcess token={token} externalID={externalID} documentType={documentCase(personValue.verificationChoice ?? "")}/>
              </ContainerShareID>

            }
            <ProjectToastContainer/>
        </>
    )
}
const ContainerShareID =(props:PropsWithChildren) =>{
    return(
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                width={'100%'} height={'100vh'} bgcolor={'#f3f5fc'}>
                <Stack width={'100%'}
                       direction={'row'}
                       alignItems={'center'}
                       paddingLeft={'3%'}
                       bgcolor={'white'} height={'6%'}>
                    <img src={certigna} className={'img-size'} alt={'logo'}/>
                </Stack>
                <Stack width={'100%'} height={'94%'} direction={'column'} justifyContent={'flex-start'}
                       alignItems={'center'}
                >
                    {props.children}
                </Stack>
            </Stack>
        </>
    )
}