import React, {useState, useEffect} from "react";
import {Box, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../component/AppContext";
import Title from "../../component/Text/Title";
import Subtitle from "../../component/Text/Subtitle";
import CountrySelect from "../../component/Form/CountrySelect";
import GridCard from "../../component/Card/GridCard";
import CustomDivider from "../../component/Layout/CustomDivider";
import CNI from "../../asset/CNI.png";
import passport from "../../asset/passport.png";
import sejour from "../../asset/titre-de-sejour.png";
import Template from "../../component/Layout/Template";
import {useNavigate} from "react-router-dom";

export default function DocumentSelection() {
    const {t} = useTranslation();
    const {personValue, documentType, setDocumentType, setBackFile} = useGlobalContext();
    const navigate = useNavigate();

    const [newDocumentType, setNewDocumentType] = useState("");

    useEffect(() => {
        if (newDocumentType !== "") {
            setDocumentType(newDocumentType);
            console.log(newDocumentType);
            if (window.location.href.includes('/classic-selfie')) {
                navigate('/classic-selfie/upload');
            } else {
                navigate('/classic/upload');
            }
        }
    }, [newDocumentType, setDocumentType, navigate]);

    const handleClick = (docType: any) => {
        if (docType === 'passport') {
            setBackFile(null);
            sessionStorage.removeItem('myBackFile');
        }
        sessionStorage.setItem('docType', docType);

        sessionStorage.removeItem('selectedFrontFileName');
        sessionStorage.removeItem('selectedBackFileName');
        sessionStorage.removeItem('myFrontFile');
        setNewDocumentType(docType);
    }
    return (
        <Template customSx={{backgroundColor: '#ffffff'}} child={
            <>
                <Title customSx={{marginTop: '90px'}} text={t('title-choix-id-1')}/>
                <Title customSx={{marginBottom: '10px'}} text={t('title-choix-id-2')}/>
                <Subtitle customSx={{color: '#525050'}} text={t('subtitle-choix-id-1')}/>
                <Subtitle customSx={{marginBottom: '20px', color: '#525050'}} text={t('subtitle-choix-id-2')}/>
                <CountrySelect/>
                <CustomDivider/>
                <Box display="grid" sx={{width: '100%', marginTop: '20px'}} gridTemplateColumns="repeat(2, 1fr)"
                     rowGap={2} columnGap={2}>
                    <GridCard
                        onClick={() => handleClick('id_card')}
                        src={CNI}
                        alt="cni"
                        firstText={t('cni-text-1')}
                        secondText={t('cni-text-2')}
                    />
                    <GridCard
                        onClick={() => handleClick('passport')}
                        src={passport}
                        alt="passport"
                        firstText={t('passport')}
                        secondText={t('')}
                    />
                    <GridCard
                        onClick={() => handleClick('residency_permit')}
                        src={sejour}
                        alt="sejour"
                        firstText={t('sejour')}
                        secondText={t('')}
                    />
                </Box>
            </>
        }/>
    );
}