import Fade from "@mui/material/Fade";
import {Stack} from "@mui/material";
import certigna from "../../asset/logo.png";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Dispatch, PropsWithChildren, SetStateAction, useEffect} from "react";
import DrawerCheckList from "./DrawerCheckList";
import PageConfirmContent from "./PageConfirmContent";
import {useGlobalContext} from "../../component/AppContext";
export interface MainValueForCheckandOtp {
    openDrawer: boolean;
    setDrawer:Dispatch<SetStateAction<boolean>>;
    textForConfirm: any[];
    imgAndWidthArray: {width: string, img: string}[],
    imgArrayText: string[];
    onSuccessRouteTo:string;
    clickConfirm:boolean,
    setClickConfirm:Dispatch<SetStateAction<boolean>>,
}
export default function PageConfirm({clickConfirm,setClickConfirm,textForConfirm,imgArrayText,imgAndWidthArray,onSuccessRouteTo,openDrawer,setDrawer}:MainValueForCheckandOtp) {
    const {t} = useTranslation()

    const {personValue} = useGlobalContext()
    useEffect(() =>{
        if(personValue.currentStep === "OTP" || personValue.currentStep ==="PHONE"){
            setDrawer(false)
        }else {
            setDrawer(true)
        }
    },[])
    return (
        <>
        <Fade in={true}>
            <Stack direction={'column'} width={'100%'} height={'100%'}>
               <HeaderComponent/>
                <ContainerWithBackground>
                    <Stack
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        className={'titleText'}
                    >
                        <span style={{fontWeight:'600'}}>{t("page1_header1")}</span>
                    </Stack>
                    <Stack
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'90%'}
                        className={'contentText'}
                    >
                        <span style={{fontWeight: '400'}}>{t("description-1")}</span>
                        <span style={{fontWeight:'600'}}>{t("description-2")}</span>
                    </Stack>
                    {/*<div className={'element_for_desktop'}>*/}
                    {/*    <Button variant={'contained'} sx={btnStyleContain}*/}
                    {/*            onClick={() => {*/}
                    {/*                // setConform(true)*/}
                    {/*                confirmAgreement(localStorage.getItem('caseID') ?? "").then(() =>{*/}
                    {/*                    setClickConfirm(true)*/}
                    {/*                }).catch((err) =>{*/}
                    {/*                    console.log(err)*/}
                    {/*                })*/}
                    {/*            }}>{t('commencer')}</Button>*/}
                    {/*</div>*/}
                </ContainerWithBackground>
                <PageConfirmContent isConfirm={clickConfirm} setConfirm={setClickConfirm} textArray={imgArrayText} imgAndWidthArray={imgAndWidthArray} onSuccessRouteTo={onSuccessRouteTo}/>
                <DrawerCheckList open={openDrawer} setDrawer={setDrawer} textArray={textForConfirm}/>
            </Stack>
        </Fade>
</>
)
}
const ContainerWithBackground = (props:PropsWithChildren) => {
  return(
      <>
      <Stack
          className={'backgroundImage'}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={3}
      >
          {props.children}

      </Stack>

      </>
  )
}
export const HeaderComponent = () =>{
    return(
        <>
            <Stack width={'100%'} height={'56px'} direction={'row'} alignItems={'center'} paddingLeft={'10px'}>
                <img src={certigna} className={'logoCertigna'} alt={'logo'}/>
            </Stack>
        </>
    )
}