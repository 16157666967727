import React, {createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useMemo} from "react";


export interface Person {
    firstname?: string,
    tel?: string,
    dossierName?: string,
    verificationChoice?: string,
    otpAttempts?:number,
    phoneAttempts?:number,
    retryAttempts?:number,
    status?:string,
    currentStep?:string
}


export interface GlobalContextType {
    personValue: Person,
    setPersonValue: Dispatch<SetStateAction<Person>>,
    sharedIDOnBroadSuccess: boolean,
    setSharedIDOnBroadSuccess: any,
    primaryColor: string,
    setPrimaryColor: any,
    shouldFetch: boolean,
    setShouldFetch: Dispatch<SetStateAction<boolean>>,
    otpFail: boolean,
    setOtpFail: Dispatch<SetStateAction<boolean>>,
    isMobile:boolean,
    setIsMobile:Dispatch<SetStateAction<boolean>>,
    //This haveToFetch and setHaveToFetch is use when you want to reFetch person value form back-end
    // by use setHaveToFetch and assign random string value it will reFetch person value from back-end
    haveToFetch:string,
    setHaveToFetch:Dispatch<SetStateAction<string>>,
    caseOption: string,
    setCaseOption: Dispatch<SetStateAction<string>>,
    documentType: string,
    setDocumentType: Dispatch<SetStateAction<string>>,
    option: string,
    setOption: Dispatch<SetStateAction<string>>,
    frontFile: any,
    setFrontFile: Dispatch<SetStateAction<any>>,
    backFile: any,
    setBackFile: Dispatch<SetStateAction<any>>,
    selfiePhoto: any,
    setSelfiePhoto: Dispatch<SetStateAction<any>>,
}

const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);
export const useGlobalContext = () => useContext(GlobalContext);

interface AppContextProps {
    children: ReactNode;
}


const AppContext: React.FC<AppContextProps> = ({children}) => {
    const [sharedIDOnBroadSuccess, setSharedIDOnBroadSuccess] = useState<boolean>(false)
    const [primaryColor, setPrimaryColor] = useState<string>('rgba(214, 5, 106, 1)')
    const [haveToFetch,setHaveToFetch] = useState<string>('')
    const [frontFile,setFrontFile] = useState<any>(null)
    const [backFile,setBackFile] = useState<any>(null)
    const [selfiePhoto,setSelfiePhoto] = useState<any>(null)
    const [personValue, setPersonValue] = useState<Person>({
        dossierName: undefined,
        tel: undefined,
        firstname: undefined,
        verificationChoice: undefined,
        otpAttempts:undefined,
        phoneAttempts:undefined,
        retryAttempts:undefined,
        status:undefined,
        currentStep:undefined
    })
    const [otpFail, setOtpFail] = useState<boolean>(false)
    const [shouldFetch, setShouldFetch] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [caseOption, setCaseOption] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [option, setOption] = useState('');
    const providerValue = useMemo(() => ({
        shouldFetch,
        setShouldFetch,
        personValue,
        setPersonValue,
        primaryColor,
        setPrimaryColor,
        sharedIDOnBroadSuccess,
        setSharedIDOnBroadSuccess,
        otpFail, setOtpFail,
        isMobile, setIsMobile,
        haveToFetch,setHaveToFetch,
        caseOption, setCaseOption,
        documentType, setDocumentType,
        option, setOption,
        frontFile, setFrontFile,
        backFile, setBackFile,
        selfiePhoto, setSelfiePhoto,
    }), [primaryColor, shouldFetch, personValue, sharedIDOnBroadSuccess, otpFail,haveToFetch, documentType, option, frontFile, backFile, selfiePhoto]);
    return (
        <GlobalContext.Provider
            value={providerValue}>
            {children}
        </GlobalContext.Provider>
    );
};

export default AppContext;
