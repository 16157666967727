import {useState} from "react";
import phone from "../../asset/etape-1.png";
import scan from "../../asset/etape-2.png";
import PageConfirm from "../pvid/PageConfirm";
import {useTranslation} from "react-i18next";
import faceScan from "../../asset/etape-3.png";

export default function ClassicSelfie() {
    const {t} = useTranslation()
    const [openDrawer_DA, setOpenDrawer_DA] = useState(true)
    const [confirm, setConfirm] = useState(false)
    const imageWithWidth = [{width: "20%", img: phone}, {width: "40%", img: scan}, {width: "40%", img: faceScan}]
    const textImage = [t('step-1'), t('step-2'), t("step-3")]
    const textConfirm1 =() =>{
        return(
            <>
                {t('consent1')} <span style={{textDecoration:'underline'}}>{t("consentCGU")}</span> {t("consent1.5")}
            </>
        )
    }
    const textConfirm2 =() =>{
        return(
            <>
                {t('consent2')} <span style={{textDecoration:'underline'}}>{t("consentCGU")}</span> {t("consent2.5")}
            </>
        )
    }
    const textConfirm3 =() =>{
        return(
            <>
                {t('consent3')} <span style={{textDecoration:'underline'}}>{t("consentCGU")}</span> {t("consent3.5-augmentee")}
            </>
        )
    }
    const textConfirm = [textConfirm1(),textConfirm2(),textConfirm3()]

    return (
        <>
            <PageConfirm onSuccessRouteTo={'/classic-selfie/document-selection'} setClickConfirm={setConfirm}
                         clickConfirm={confirm} imgAndWidthArray={imageWithWidth} imgArrayText={textImage}
                         setDrawer={setOpenDrawer_DA} openDrawer={openDrawer_DA} textForConfirm={textConfirm}/>
        </>
    )
}
