import * as React from 'react';
import { Button, Checkbox, Stack} from "@mui/material";
import {Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {useGlobalContext} from "../component/AppContext";
import DrawerCheckList from "./pvid/DrawerCheckList";


export default function SimpleFade() {
    const [boolArray, setBoolArray] = useState([false, false, false]);
    const {primaryColor} =useGlobalContext()
    const borderPrimary = '1px solid ' + primaryColor
    const obj = ['hwllo' , 'jhjjjd', 'jdjkdjw']
  return(
      <>
          {boolArray.map((value, index, array) =>{
              return(
                  <>
                      <CheckBoxTest setCheckList={setBoolArray} checkList={boolArray} index={index} textArray={obj}/>
                  </>
              )
          })}
          <Button variant={'contained'} disabled={!boolArray.every(Boolean)}>hello</Button>

      </>
  )
}
interface CheckBoxValue{
    checkList:boolean[],
    setCheckList:Dispatch<SetStateAction<boolean[]>>,
    index:number,
    textArray:string[]
}
const CheckBoxTest = ({setCheckList,checkList,index,textArray}: CheckBoxValue) => {
    const [isCheck,setCheck] = useState(false)
    const {primaryColor} =useGlobalContext()
    const borderPrimary = '1px solid '+ primaryColor
    const handleChange = (index:any, value:any) => {
        setCheckList(prevBoolArray => {
            const newBoolArray = [...prevBoolArray];
            newBoolArray[index] = value;
            return newBoolArray;
        });
        setCheck(value)
        console.log(checkList)
    }
    return (
        <>

        </>
    )
}
export function FullScreenCenter(props:PropsWithChildren){
    return(
        <>
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'} >
                {props.children}
            </Stack>
        </>
    )
}
export function DrawerTesting(){
    const [open,setDrawer] = useState(true)
        const {personValue} = useGlobalContext()
    const text = ['J’ai lu et j’accepte les cgu liés aux conditions d’utilisation' , 'J’ai lu et j’accepte les cgu liés à mes documents d’identité','kkkkk']
    return(
        <>
        </>
    )
}