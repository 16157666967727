import axios from "axios";

const response = await axios.get('/envConfig.json')
export const IDENTITY_VERIFICATION_URL = response.data.GATEWAY_URL + response.data.API_IDENTITY_VERIFICATION_CONTEXT_PATH ?? ""
export const PROCESS_CONTROL_URL = response.data.GATEWAY_URL + response.data.API_PROCESS_CONTROL_CONTEXT_PATH;

export const envConfig= {
    createProject: IDENTITY_VERIFICATION_URL + process.env.REACT_APP_CREATE_PROJECT,
    getProjectIDAndFlowID: PROCESS_CONTROL_URL + process.env.REACT_APP_PROJECT_CASEID_AND_FLOWID,
    getProjectCaseID: PROCESS_CONTROL_URL + process.env.REACT_APP_PROJECT_CASEID,
    getMailOpen: PROCESS_CONTROL_URL + process.env.REACT_APP_PROJECT_MAIL_OPEN,
    sendSMS: PROCESS_CONTROL_URL + process.env.REACT_APP_SMS_SEND,
    validateSMS: PROCESS_CONTROL_URL + process.env.REACT_APP_SMS_VALIDATE,
    confirmAgreement: PROCESS_CONTROL_URL + process.env.REACT_APP_PROJECT_CASEID+process.env.REACT_APP_CONFIRM_AGREEMENT,
    postUUID: PROCESS_CONTROL_URL + process.env.REACT_APP_PROJECT_CASEID+process.env.REACT_APP_POST_UUID,
    verifyShareId: PROCESS_CONTROL_URL + process.env.REACT_APP_VERIFY_SHARE_ID,
    verifyShareIdResponse: PROCESS_CONTROL_URL + process.env.REACT_APP_VERIFY_SHARE_ID + process.env.REACT_APP_VERIFY_RESPONSE,
    projectUserConsent: PROCESS_CONTROL_URL + process.env.REACT_APP_CONSENT
}