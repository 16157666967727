
import axios, { AxiosResponse } from 'axios';

export const getUserPersonalInformation = async (): Promise<UserPersonalInformation> => {
    try {
        const response: AxiosResponse<UserPersonalInformation> = await axios.get('https://api.ipify.org?format=json');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to get user personal information');
    }
};


interface UserPersonalInformation{
    ip: string;
}