import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {callMailOpen, getCaseID} from "../service/createProject";

export default function MainPageProduction(){
    // const {id} = useParams()
    const location= useLocation();
    const searchParams = new URLSearchParams(location.search);
    const flowId = searchParams.get('flowId')
    const dossierId = searchParams.get('dossierId')
    const router = useNavigate()
    useEffect(() =>{
        localStorage.clear();
        getCaseID(dossierId ?? "").then((value) =>{
            const caseID = dossierId
            localStorage.setItem("caseID", caseID ?? "")
            localStorage.setItem('flowId',flowId ??"")
            const dataValue = value.data.verificationChoice
            console.log(dataValue)
            if (value.data.status === "SHARE_ID_VERIFIED") {
                if (dataValue === "PVID" || dataValue === "AUTH_DOCUMENT") {
                    router("/success")
                } else if (dataValue === "CLASSIC_DOCUMENT") {
                    router("/classic/share-id/valid")
                }
                // else {
                //     router("/classic-selfie/share-id/valid")
                // }
            } else if (dataValue.status === "BANNED") {
                router("/page-block")
            } else {
                callMailOpen(dossierId ?? "", flowId ?? "").then();
                switch (dataValue) {
                    case "PVID":
                        router("/pvid");
                        break;
                    case "AUTH_DOCUMENT":
                        router("/document-authenticity/");
                        break;
                    case "CLASSIC_DOCUMENT":
                        router("/classic/");
                        break;
                    // case "CLASSIC_DOCUMENT_SELFIE":
                    //     router("/classic-selfie/");
                    //     break;
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    },[])
    console.log()
    return(
        <>

        </>
    )
}