import React, {useEffect, useState} from 'react';
import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import {BrowserRouter, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import MainPage from "./page/MainPage";

import PhotoRecto from "./component/Photo/PhotoRecto";
import {useGlobalContext} from "./component/AppContext";

import ShareIDFrom from "./page/shareIDProcess/ShareIDFrom";
import 'react-toastify/dist/ReactToastify.css';
import {getCaseID} from "./service/createProject";
import DocumentSelection from "./page/CustomShareID/DocumentSelection";
import DocumentUpload from "./page/CustomShareID/DocumentUpload";
import PageBlock from "./page/pageblock/PageBlock";
import BlackPage from "./page/BlackPage";
import PVID_process from "./page/pvid/PVID_process";
import {DrawerTesting} from "./page/TestingComponent";
import Document_auth_process from "./page/document_authenticity/Document_auth_process";
import LastPage from "./page/pvid/LastPage";
import Loading from "./page/CustomShareID/Loading";
import Refuse from "./page/CustomShareID/Refuse";
import Valid from "./page/CustomShareID/Valid";
import Blank from "./page/CustomShareID/Blank";
import Classic from "./page/verification_classic/Classic";
import Selfie from "./page/CustomShareID/Selfie";
import ClassicSelfie
    from "./page/verification_classic_selfie/ClassicSelfie";
import MainPageProduction from "./page/MainPageProduction";
import axios from "axios";

function ContainerForComponent() {

    const router = useNavigate()
    const {setPersonValue, personValue,haveToFetch} = useGlobalContext();
    const [shouldRender,setShouldRender] =useState(false)
    useEffect(() => {
        if (localStorage.getItem('caseID')) {
            const id = localStorage.getItem('caseID') ?? ""
            const flowId = localStorage.getItem('flowId') ?? ""
            getCaseID(id).then((value: any) => {
                const data = value.data
                setPersonValue({
                    ...personValue,
                    tel: data.tel,
                    verificationChoice: data.verificationChoice,
                    firstname: data.firstname,
                    dossierName: data.dossierName,
                    phoneAttempts: data.phoneAttempts,
                    otpAttempts: data.otpAttempts,
                    retryAttempts: data.retryAttempts,
                    status: data.status,
                    currentStep: data.currentStep
                })
            }).catch((err) => {
                console.log(err)
            })
        } else {
            router('/')
        }
        if (personValue.status === "BANNED") {
            router("/page-block")
        }


    }, [haveToFetch])
    useEffect(() => {
        checkPersonValue()
    }, [personValue])

    function checkPersonValue() {
        if (Object.values(personValue).every(value => value !== null && value !== undefined)) {
            setShouldRender(true)
        } else {
            setShouldRender(false)
        }
    }
    return (
        <div style={{height: '100%', width: '100%'}}>
            {shouldRender && <Outlet/>}
        </div>
    )

}

function App() {
    const {primaryColor, setIsMobile} = useGlobalContext()

    const theme = createTheme({
        palette: {
            primary: {
                main: primaryColor
            }
        }
    });

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json').then((v) =>{
            console.log(v)
        })
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div style={{width: '100vw', height: '100dvh'}}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<ContainerForComponent/>}>
                            <Route path={'/shareid-onboarding-qrcode'} element={<ShareIDFrom/>}/>
                            <Route path={'/page-block'} element={<PageBlock/>} />
                            <Route path={'/success'} element={<LastPage/>}/>
                            <Route path={'/pvid'} element={<PVID_process/>}/>
                            <Route path={'/classic'} element={<Classic/>}/>
                            <Route path={'/classic-selfie'} element={<ClassicSelfie/>}/>
                            <Route path={'/document-authenticity'} element={<Document_auth_process/>}/>
                            <Route path={'/pop'} element={<DrawerTesting/>}/>
                        </Route>
                        <Route path={'/'} element={<MainPage/>}/>
                        <Route path={'/blank-page'} element={<BlackPage/>}/>
                        <Route path={'/:pathName/document-selection'} element={<DocumentSelection/>}/>
                        <Route path={'/:pathName/upload'} element={<DocumentUpload/>}/>
                        <Route path={'/:pathName/capture-image'} element={<PhotoRecto/>}/>
                        <Route path={'/:pathName/selfie'} element={<Selfie/>}/>
                        <Route path={'/:pathName/share-id/loading'} element={<Loading/>}/>
                        <Route path={'/:pathName/share-id/refuse'} element={<Refuse/>}/>
                        <Route path={'/:pathName/share-id/valid'} element={<Valid/>}/>
                        <Route path={'/blank'} element={<Blank/>}/>
                        <Route path={'/shareid-onboarding-qrcode'} element={<ShareIDFrom/>}/>
                        <Route path={'/main'} element={<MainPageProduction/>}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );

}

export default App;
