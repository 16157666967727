import React, {useEffect} from "react";
import Template from "../../component/Layout/Template";
import {Box, Button, CircularProgress} from "@mui/material";
import Title from "../../component/Text/Title";
import {useTranslation} from "react-i18next";
import Subtitle from "../../component/Text/Subtitle";
import {useGlobalContext} from "../../component/AppContext";

export default function Loading() {
    // useEffect(() => {
    //     // Prevent navigating back
    //     window.history.pushState(null, document.title, window.location.href);
    //
    //     // Add event listener to handle back navigation attempts
    //     window.addEventListener('popstate', handleBackNavigation);
    //
    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('popstate', handleBackNavigation);
    //     };
    // }, []);
    //
    // // Function to handle back navigation attempts
    // const handleBackNavigation = () => {
    //     // Perform any action you want before allowing navigation back
    //     // For example, you can show a message or confirmation dialog
    //
    //     // If you want to allow navigation back, you can remove the following line
    //     window.history.pushState(null, document.title, window.location.href);
    // };
    const { t } = useTranslation();
    const { primaryColor} = useGlobalContext();
    return (
        <Template child={
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '90vh'}}>
                <CircularProgress size={60} thickness={3} sx={{marginBottom: '10px'}}/>
                <Title text={t('document-analyse-1')} />
                <Title customSx={{marginBottom: '10px'}} text={t('document-analyse-2')} />
                {/*<Button sx={{textTransform: 'none'}}>*/}
                {/*    <Subtitle customSx={{fontWeight: 600, fontSize: '13px', color: primaryColor}} text={t('cancel')} />*/}
                {/*</Button>*/}
            </Box>
        }/>
    );
}