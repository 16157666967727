import React, {useState, useRef, ChangeEvent, useEffect, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import Template from "../../component/Layout/Template";
import Title from "../../component/Text/Title";
import Subtitle from "../../component/Text/Subtitle";
import {useTranslation} from "react-i18next";
import {FlexCard} from "../../component/Card/FlexCard";
import CNI from "../../asset/CNI.png";
import passport from "../../asset/passport.png";
import sejour from "../../asset/titre-de-sejour.png"
import recto from "../../asset/recto.png";
import verso from "../../asset/verso.png";
import {Box, Button} from "@mui/material";
import {useGlobalContext} from "../../component/AppContext";
import CustomDivider from "../../component/Layout/CustomDivider";
import upload from "../../asset/upload.png";
import CommonButton from "../../component/Button/CommonButton";
import CommonImage from "../../component/Photo/CommonImage";
import SuccessButton from "../../component/Button/SuccessButton";
import RemoveButton from "../../component/Button/RemoveButton";
import ContainedButton from "../../component/Button/ContainedButton";
import {getPersonValue} from "../../service/createProject";
import {envConfig, IDENTITY_VERIFICATION_URL} from "../../service/allEndpoint";

export default function DocumentUpload() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [choice, setChoice] = useState("");
    const [frontFileErrorMessage, setFrontFileErrorMessage] = useState("");
    const [backFileErrorMessage, setBackFileErrorMessage] = useState("");

    const [nextButton, setNextButton] = useState(false);
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [frontFileLocal, setFrontFileLocal] = useState<any>(null);
    const [backFileLocal, setBackFileLocal] = useState<any>(null);

    const {
        personValue,
        setPersonValue,
        primaryColor,
        documentType,
        frontFile,
        backFile,
        setFrontFile,
        setBackFile,
        setSelfiePhoto
    } = useGlobalContext();
    const frontFileInputRef = useRef<HTMLInputElement | null>(null);
    const backFileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFrontFileName, setSelectedFrontFileName] = useState<string | null>(
        sessionStorage.getItem("selectedFrontFileName")
    );
    const [selectedBackFileName, setSelectedBackFileName] = useState<string | null>(
        sessionStorage.getItem("selectedBackFileName")
    );

    useEffect(() => {
    }, [frontFile, backFile, choice, documentType]);

    useEffect(() => {
        const retrievedFrontFile = retrieveFrontFileFromLocalStorage();
        if (retrievedFrontFile) {
            setFrontFileLocal(retrievedFrontFile);
        }

        if (sessionStorage.getItem('docType') !== 'passport') {
            const retrievedBackFile = retrieveBackFileFromLocalStorage();
            if (retrievedBackFile) {
                setBackFileLocal(retrievedBackFile);
            }
        }

    }, []);

    // useEffect(() => {
    //     getPersonValue().then((data)=>{
    //         if (data.verificationChoice === 'CLASSIC_DOCUMENT_SELFIE') {
    //             setNextButton(true);
    //         }
    //     })
    // }, [])

    const uploadFrontPhoto = () => {
        sessionStorage.setItem('option', 'front');
        setChoice("upload");
        if (frontFileInputRef.current) {
            frontFileInputRef.current.click();
        }
    };

    const handleFrontFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const allowedTypes = ['.png', '.jpeg', '.jpg', '.webp', '.pdf'];
        const selectedFile = event.target.files?.[0];
        setFrontFileErrorMessage("");
        if (selectedFile) {
            const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();
            if (!allowedTypes.includes(fileType)) {
                setFrontFileErrorMessage('Unsupported file type. Please select a valid file.');
            } else {
                setFrontFileErrorMessage('');
            }
            setFrontFile(selectedFile);
            console.log(`Selected File: ${selectedFile.name}`);
            setSelectedFrontFileName(selectedFile.name);
            sessionStorage.setItem("selectedFrontFileName", selectedFile.name);
            setFrontFileLocal(selectedFile); // Update the local state
            storeFrontFileInLocalStorage(selectedFile);
            retrieveFrontFileFromLocalStorage();
        }
    };

    const storeFrontFileInLocalStorage = (file: File) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const dataURL = event.target?.result as string;
            sessionStorage.setItem('myFrontFile', dataURL);
        };

        reader.readAsDataURL(file);
    };

    const retrieveFrontFileFromLocalStorage = (): File | null => {
        const storedData = sessionStorage.getItem('myFrontFile');
        if (storedData) {
            const fileType = storedData.split(';')[0].split('/')[1];
            const byteCharacters = atob(storedData.split(',')[1]);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: `image/${fileType}` });
            const retrievedFile = new File([blob], `retrieved.${fileType}`, { type: `image/${fileType}` });
            console.log(retrievedFile)
            return retrievedFile;
        }
        return null;

    };

    const takeFrontPhoto = () => {
        sessionStorage.setItem('option', 'front');
        setChoice("take");
        navigate('/classic/capture-image');
    }

    const replaceFrontPhoto = () => {
        sessionStorage.removeItem('selectedFrontFileName')
        setSelectedFrontFileName("");
        setFrontFileErrorMessage("");
        setFrontFile(null);
        sessionStorage.removeItem('myFrontFile');
        if (frontFileInputRef.current) {
            frontFileInputRef.current.value = "";
        }
    };

    const replaceBackPhoto = () => {
        sessionStorage.removeItem('selectedBackFileName')
        setSelectedBackFileName("");
        setBackFileErrorMessage("");
        setBackFile(null);
        sessionStorage.removeItem('myBackFile');
        if (backFileInputRef.current) {
            backFileInputRef.current.value = "";
        }
    };

    const uploadBackPhoto = () => {
        sessionStorage.setItem('option', 'back');
        setChoice("upload");
        if (backFileInputRef.current) {
            backFileInputRef.current.click();
        }
    };

    const handleBackFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const allowedTypes = ['.png', '.jpeg', '.jpg', '.webp', '.pdf'];
        const selectedFile = event.target.files?.[0];
        setBackFileErrorMessage("");
        if (selectedFile) {
            const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();
            if (!allowedTypes.includes(fileType)) {
                setBackFileErrorMessage('Unsupported file type. Please select a valid file.');
            } else {
                setBackFileErrorMessage('');
            }
            setBackFile(selectedFile);
            console.log(`Selected File: ${selectedFile.name}`);
            setSelectedBackFileName(selectedFile.name);
            sessionStorage.setItem("selectedBackFileName", selectedFile.name);
            setBackFileLocal(selectedFile); // Update the local state
            storeBackFileInLocalStorage(selectedFile);
            retrieveBackFileFromLocalStorage();
        }
    };

    const storeBackFileInLocalStorage = (file: File) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const dataURL = event.target?.result as string;
            sessionStorage.setItem('myBackFile', dataURL);
        };

        reader.readAsDataURL(file);
    };

    const retrieveBackFileFromLocalStorage = (): File | null => {
        const storedData = sessionStorage.getItem('myBackFile');
        if (storedData) {
            const fileType = storedData.split(';')[0].split('/')[1];
            const byteCharacters = atob(storedData.split(',')[1]);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: `image/${fileType}` });
            const retrievedFile = new File([blob], `retrieved.${fileType}`, { type: `image/${fileType}` });
            console.log(retrievedFile)
            return retrievedFile;
        }
        return null;

    };

    const takeBackPhoto = () => {
        sessionStorage.setItem('option', 'back');
        setChoice("take");
        navigate('/classic/capture-image');
    }

    const verifyDocument = () => {
        navigate('/classic/share-id/loading');
        const formData = new FormData();

        console.log(frontFileLocal)
        retrieveFrontFileFromLocalStorage();

        console.log(frontFile);
        console.log(backFile);
        formData.append('documentFront', frontFileLocal);
        if (sessionStorage.getItem('docType') !== 'passport') {
            retrieveBackFileFromLocalStorage();
            formData.append('documentBack', backFileLocal);
        }

        const documentCountry = sessionStorage.getItem('documentCountry');
        if (documentCountry !== null) {
            formData.append('documentCountry', documentCountry);
        } else {
            formData.append('documentCountry', 'fr');
        }
        const docType = sessionStorage.getItem('docType');
        if (docType !== null) {
            formData.append('documentType', docType);
        } else {
            formData.append('documentType', 'id_card');
        }
        formData.append('documentRotation', '0');

        fetch(envConfig.verifyShareId + '?flowId=' + localStorage.getItem('flowId') + '&dossierId=' + localStorage.getItem('caseID') , {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.payload.id) {
                    localStorage.setItem('verificationId', data.payload.id);
                    fetch(envConfig.verifyShareIdResponse + '?flowId=' + localStorage.getItem('flowId') + '&dossierId=' + localStorage.getItem('caseID') + '&verificationId=' + localStorage.getItem('verificationId') , {
                        method: 'POST',
                    }).then(v => v.json())
                        .then((value) => {
                        if (!value.status) {
                           navigate('/classic/share-id/refuse');
                        } else {
                           navigate('/classic/share-id/valid');
                        }
                    }).catch((error) => {
                        navigate('/classic/share-id/refuse');
                        console.error('Error:', error);
                    });

                    setFrontFile(null);
                    if (sessionStorage.getItem('docType') !== 'passport') {
                        setBackFile(null);
                        sessionStorage.removeItem('myBackFile');
                    }
                    // setDocumentType('');
                    sessionStorage.removeItem('docType');
                    sessionStorage.removeItem('documentCountry');
                    sessionStorage.removeItem('myFrontFile');
                }

            })
            .catch((error) => {
                navigate('/classic/share-id/refuse');
                console.error('Error:', error);
            });
    }

    const isMobileDevice = (): boolean => {
        const userAgent: string = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };

    return (
        <Template customSx={{backgroundColor: '#ffffff'}} child={
            <>
                <Title customSx={{marginTop: '90px', marginBottom: '10px'}} text={t('title-charger')}/>
                {
                    (sessionStorage.getItem('docType') !== 'passport')
                    ? (
                        <>
                            <Subtitle customSx={{color: '#525050'}} text={t('subtitle-charger-1')}/>
                            <Subtitle customSx={{marginBottom: '20px', color: '#525050'}}
                                      text={t('subtitle-charger-2')}/>
                        </>)
                    : (
                        <>
                            <Subtitle customSx={{color: '#525050'}} text={t('subtitle-charger-passport-1')}/>
                            <Subtitle customSx={{marginBottom: '20px', color: '#525050'}}
                                      text={t('subtitle-charger-passport-2')}/>
                        </>)
                }

                <FlexCard marginTop={0} marginBottom={'20px'}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {(sessionStorage.getItem('docType') === 'residency_permit') ?
                            <CommonImage style={{width: '40px', height: '30.14px'}} alt="sejour" src={sejour}/>
                            : (sessionStorage.getItem('docType')=== 'passport') ?
                                <CommonImage style={{width: '40px'}} alt="passport" src={passport}/>
                                : <CommonImage style={{width: '40px', height: '30.14px'}} alt="cni" src={CNI}/>}
                        <Box sx={{marginLeft: '20px'}}>
                            {(sessionStorage.getItem('docType') === 'residency_permit') ?
                                <Subtitle customSx={{display: 'flex', flexGrow: 1}}
                                          text={t('sejour')}/>
                                : (sessionStorage.getItem('docType') === 'passport') ?
                                    <Subtitle customSx={{display: 'flex', flexGrow: 1}}
                                              text={t('passport')}/>
                                    : <Subtitle customSx={{display: 'flex', flexGrow: 1}}
                                                text={t('cni-text-1') + " " + t('cni-text-2')}/>}

                            <Button sx={{textTransform: 'none', display: 'flex', padding: 0}}
                                    onClick={() => {
                                        sessionStorage.removeItem('selectedFrontFileName');
                                        sessionStorage.removeItem('selectedBackFileName');
                                        if (window.location.href.includes('/classic-selfie')) {
                                            navigate('/classic-selfie/document-selection');
                                        } else {
                                            navigate('/classic/document-selection');
                                        }
                                    }}>
                                <Subtitle customSx={{fontWeight: 600, fontSize: '11px', color: primaryColor}}
                                          text={t('change-doc')}/>
                            </Button>
                        </Box>
                    </Box>
                </FlexCard>

                <CustomDivider/>

                <FlexCard marginTop={'20px'} marginBottom={'0px'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex'}}>
                            <CommonImage style={{width: '20px'}} alt="recto" src={recto}/>
                            {
                                (sessionStorage.getItem('docType') !== 'passport')
                                    ? (
                                        <Title customSx={{marginLeft: '10px', fontSize: '14px'}} text={t('recto')}/>)
                                    : (
                                        <Title customSx={{marginLeft: '10px', fontSize: '14px'}} text={t('passport-doc')}/>)
                            }
                        </Box>
                        {selectedFrontFileName && (<SuccessButton/>)}
                    </Box>
                    <input
                        key={selectedFrontFileName}
                        type="file"
                        accept=".png, .jpeg, .jpg, .webp, .pdf"
                        style={{display: 'none'}}
                        ref={frontFileInputRef}
                        onChange={handleFrontFileChange}
                    />
                    {selectedFrontFileName ? (
                        (frontFileErrorMessage !== "") ?
                            <Subtitle customSx={{
                                fontWeight: 600,
                                fontSize: '11px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                color: '#CE0500',
                            }} text={frontFileErrorMessage}/>
                            : <Subtitle customSx={{
                                fontWeight: 400,
                                fontSize: '12px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                color: 'black',
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                                textAlign: 'start'
                            }} text={selectedFrontFileName}/>
                    ) : (<>
                        <Subtitle customSx={{color: '#525050', marginTop: '10px', marginBottom: '10px'}}
                                  text={t('file-description')}/>

                        <CommonButton onClick={uploadFrontPhoto} customSx={{marginBottom: '10px'}} src={upload}
                                      alt={upload}
                                      text={t('upload', {choice: 'recto'})}/>
                        {/*<CommonButton onClick={takeFrontPhoto} src={camera} alt={camera}*/}
                        {/*              text={t('camera', {choice: 'recto'})}/>*/}

                    </>)}
                    {selectedFrontFileName && (<><RemoveButton onClick={replaceFrontPhoto}/></>)}
                </FlexCard>

                {
                    (sessionStorage.getItem('docType') !== 'passport') ?
                        <FlexCard marginTop={'10px'} marginBottom={'40px'}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                <Box sx={{display: 'flex'}}>
                                    <CommonImage style={{width: '20px'}} alt="alt" src={verso}/>
                                    <Title customSx={{marginLeft: '10px', fontSize: '14px'}} text={t('verso')}/>
                                </Box>
                                {selectedBackFileName && (<SuccessButton/>)}
                            </Box>
                            <input
                                key={selectedBackFileName}
                                type="file"
                                accept=".png, .jpeg, .jpg, .webp, .pdf"
                                style={{display: 'none'}}
                                ref={backFileInputRef}
                                onChange={handleBackFileChange}
                            />
                            {selectedBackFileName ? (
                                (backFileErrorMessage !== "") ?
                                    <Subtitle customSx={{
                                        fontWeight: 600,
                                        fontSize: '11px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        color: '#CE0500',
                                    }} text={backFileErrorMessage}/>
                                    : <Subtitle customSx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        color: 'black',
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                        textAlign: 'start'
                                    }} text={selectedBackFileName}/>
                            ) : (<>
                                <Subtitle customSx={{color: '#525050', marginTop: '10px', marginBottom: '10px'}}
                                          text={t('file-description')}/>
                                <CommonButton onClick={uploadBackPhoto} customSx={{marginBottom: '10px'}} src={upload}
                                              alt={upload}
                                              text={t('upload', {choice: 'verso'})}/>
                                {/*<CommonButton onClick={takeBackPhoto} customSx={{marginBottom: '10px'}} src={camera}*/}
                                {/*              alt={camera}*/}
                                {/*              text={t('camera', {choice: 'verso'})}/>*/}
                            </>)}
                            {selectedBackFileName && (<><RemoveButton onClick={replaceBackPhoto}/></>)}
                        </FlexCard>
                        : <></>
                }

                {((sessionStorage.getItem('docType') === 'passport') ?
                        (frontFileErrorMessage === "" && selectedFrontFileName) :
                        (frontFileErrorMessage === "" && backFileErrorMessage === "" && selectedFrontFileName && selectedBackFileName))
                    && (
                        (window.location.href.includes('/classic-selfie')) ?
                        // (nextButton) ?
                            <Box sx={{
                                display: 'flex',
                                padding: '16px 24px 16px 24px',
                                width: '100vw',
                                backgroundColor: 'white',
                                position: 'fixed',
                                bottom: 0,
                                border: 'none'
                            }}
                            >
                                <ContainedButton
                                    text={t('suivant')}
                                    onClick={
                                        () => {
                                            setSelfiePhoto(null);
                                            navigate('/classic-selfie/selfie');
                                        }
                                    }/>
                            </Box> :
                            <Box sx={{
                                display: 'flex',
                                padding: '16px 24px 16px 24px',
                                width: '100vw',
                                backgroundColor: 'white',
                                position: 'fixed',
                                bottom: 0,
                                border: 'none'
                            }}
                            >
                                <ContainedButton
                                    text={t('verify')}
                                    onClick={verifyDocument}/>
                            </Box>
                    )}
            </>
        }/>
    );
}