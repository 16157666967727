import {MuiOtpInput} from "mui-one-time-password-input";
import {Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {Button,Stack} from "@mui/material";
import VerifyOTP from "./VerifyOTP";
import {btnStyleContain} from "../../component/ButtonStyleComponent";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../component/AppContext";
import {generateRandomString, sendSMS} from "../../service/createProject";
import FailProcess from "./FailProcess";
import {useNavigate} from "react-router-dom";
import PopupModelV2 from "../../component/PopupModelV2";

interface OtpProps {
    onSuccess:string;
}
export default function VerifyPhoneNumber({onSuccess}:OtpProps) {
    const [optStep1, setOptStep1] = useState(false)
    return (
        <>
            {optStep1 ?
                <ContainerProps>
                    <VerifyOTP onSuccess={onSuccess}/>
                </ContainerProps> :
                <ContainerProps>
                    <BasicModel setOptStep1={setOptStep1}/>
                </ContainerProps>
            }
        </>
    )
}
const ContainerProps = (props: PropsWithChildren) => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                {props.children}
            </div>
        </>
    )
}

interface ValueForm {
    setOptStep1: Dispatch<SetStateAction<boolean>>,
}


const BasicModel = (value: ValueForm) => {
    const [open, setOpen] = useState(true);
    const [otp, setOtp] = useState("");
    const [showError, setShowError] = useState(false)
    const {t} = useTranslation()
    const {personValue, setHaveToFetch} = useGlobalContext()
    const [retry, setRetry] = useState(3)
    const router = useNavigate()
    const postSMS = () => {
        if (personValue.tel) {
            const obj = {
                dossierId: localStorage.getItem('caseID') ?? '',
                lastFourDigits: otp
            }
            sendSMS(obj).then((value2: any) => {
                console.log(personValue)
                console.log(value2)
                setOpen(false)
                value.setOptStep1(true)
                // console.log(value2)
                setHaveToFetch(generateRandomString)
            }).catch((error: any) => {
                setOtp('')
                setHaveToFetch('fetch')
                if(error.response.status === 403){
                    setOpen(false)
                }
                if(error.response.data.subErrors){
                    const value = error.response.data.subErrors[0].attempts
                    attempt(value)
                }
                setShowError(true)
                document.getElementsByClassName('MuiOtpInput-TextField-1').item(0)?.getElementsByTagName("input").item(0)?.focus()
            })
        }
    }
    const attempt = (value: number) => {
        if (value === 1) {
            setRetry(2)
        } else if (value === 2) {
            setRetry(1)
        } else if (value === 3) {
            setRetry(0)
            setOpen(false)
        }
    }
    useEffect(() => {
        console.log(open)
        if (personValue.currentStep === "OTP") {
            value.setOptStep1(true)
        }
        console.log(personValue)
        if (personValue.phoneAttempts === 0) {
            setShowError(false)
        } else {
            setShowError(true)
            attempt(personValue.phoneAttempts ?? 3)
        }
        if (personValue.status === 'BANNED' && open) {
            router('/page-block')
        }

    }, [])

    return (
        <>
            <PopupModelV2 open={open}>
               <div className={'flexColumn_jc_ac'} style={{gap:'30px',width:'100%'}}>
                   <div className={'flexColumn_jc_ac'} style={{gap:'9px',width:'100%'}}>
                       <span style={{fontWeight: 600}} className={'subTitle'}>{t("phone-dialog-title")}</span>
                           <span className={'contentText'}>
                              {t("phone-dialog-description")}
                               <span style={{fontWeight: 600}}>{personValue.tel}</span>
                           </span>
                   </div>
                       <div className={'flexColumn_jc_ac'} style={{gap:'30px',width:'70%'}}>
                       <Stack width={'90%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                           <div>
                           <Otp4Characters setValueOpt={setOtp} ValueOpt={otp} />
                               {showError&&
                                   <div style={{width:'100%',textAlign:'start'}}>
                                    <span style={{color:'red',fontWeight:300,fontSize:'12px'}}
                                    >
                                     { retry === 2 ? <span>
                                            {t("errorAttempt4degits", {value: retry})}
                                        </span>
                                         :
                                         <span>
                                     {t("errorAttempt4degit", {value: retry})}
                                        </span>}

                                    </span></div>}
                           </div>
                       </Stack>
                               <Button
                                   variant={'contained'}
                                   fullWidth={true}
                                   disabled={otp.replace(/\D/g, "").length < 4}
                                   sx={btnStyleContain}
                                   style={{
                                       minWidth:'280px',
                                       maxWidth:'283px'
                                   }}
                                   onClick={() => {
                                   postSMS()
                                   setHaveToFetch("fetch")
                               }}
                                   autoFocus={true}
                               >{t("valider")}</Button>
                       </div>

               </div>
            </PopupModelV2>
            {!open && <FailProcess/>}
        </>

    );
}

interface Props {
    setValueOpt: Dispatch<SetStateAction<any>>,
    ValueOpt: string
}

const Otp4Characters = ({setValueOpt, ValueOpt}: Props) => {

    const handleChange = (newValue: any) => {
        console.log(newValue)
        setValueOpt(newValue)
    };
    const StyleForInput = {
        "& .MuiInputBase-root": {
            width: "56px",
            height: "55px",
            background: 'white',
            borderRadius: "5px",
        }

    };
    useEffect(() =>{
            document.getElementsByClassName('MuiOtpInput-TextField-1').item(0)?.getElementsByTagName("input").item(0)?.focus()
    },[])
    return (
        <>
            <MuiOtpInput
                onKeyPress={(e: any) => {
                    if (!/[0-9]/.test(e.nativeEvent.key)) {
                        return e.preventDefault();
                    }
                }}
                inputMode={'numeric'}
                TextFieldsProps={{placeholder: '_',inputProps:{inputMode:'numeric',pattern:'[0-9]*'}}}
                gap={0.5}
                value={ValueOpt}
                onFocus={event =>{event.target.focus()}}
                onChange={handleChange}
                length={4}
                sx={StyleForInput}
                validateChar={(val) => !isNaN(Number(val))}
            />
        </>
    )
}
export const styleCard = {
    position: 'absolute',
    top: '30vh',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '12px',
    padding: '24px 32px 24px 32px',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%', // Default width
    '@media only screen and (max-height: 800px)': {
        top:'38vh' // Width for mobile screens
    },
    '@media only screen and (min-width: 600px)': {
        width:'347px' // Width for mobile screens
    },

}