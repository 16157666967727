import React, {useEffect} from "react";
import {Box, Button, TextField} from "@mui/material";
import {btnStyleContain} from "../component/ButtonStyleComponent";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../component/AppContext";
import {createProject} from "../service/createProject";

export default function MainPage() {
    const {t} = useTranslation();
    const router = useNavigate();


    const {personValue, setPersonValue, setHaveToFetch, haveToFetch} = useGlobalContext()

    const handleChange = (event: any) => {
        setPersonValue((prev: any) => {
            return {
                ...prev, [event.target.name]: event.target.value
            }
        })
    }
    const handleDisable = () => {
        return !(personValue.tel != '' && personValue.dossierName != '' && personValue.firstname != '');
    }
    useEffect(() => {
        router('/main');
        // setPersonValue({...personValue,
        //     dossierName: '',
        //     tel: '',
        //     firstname: '',
        //     verificationChoice: undefined,
        //     otpAttempts:undefined,
        //     phoneAttempts:undefined,
        //     retryAttempts:undefined,
        //     status:undefined,
        //     currentStep:undefined
        // })
    }, [])
    return (
        <>
            {/*<div*/}
            {/*    style={{width: "100%", height: "100%"}}*/}
            {/*    className={"flexColumn_jc_ac"}*/}
            {/*>*/}
            {/*    <Box sx={{m: "20px 20px 100px 20px"}}>*/}
            {/*        <TextField*/}
            {/*            name={'firstname'}*/}
            {/*            onChange={handleChange}*/}
            {/*            sx={{mb: "10px"}}*/}
            {/*            label="Name"*/}
            {/*            variant="outlined"*/}
            {/*            fullWidth={true}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            name={'tel'}*/}
            {/*            // value={personValue.tel}*/}
            {/*            onChange={handleChange}*/}
            {/*            sx={{mb: "10px"}}*/}
            {/*            label="Telephone"*/}
            {/*            variant="outlined"*/}
            {/*            fullWidth={true}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            name={'dossierName'}*/}
            {/*            // value={personValue.caseName}*/}
            {/*            onChange={handleChange}*/}
            {/*            label="Dossier name"*/}
            {/*            variant="outlined"*/}
            {/*            fullWidth={true}*/}
            {/*        />*/}
            {/*    </Box>*/}
            {/*    <div*/}
            {/*        className={"flexColumn_jc_ac"}*/}
            {/*        style={{width: "90%", gap: "10px"}}*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            sx={btnStyleContain}*/}
            {/*            variant={"contained"}*/}
            {/*            fullWidth={true}*/}
            {/*            onClick={() => {*/}
            {/*                const obj = {*/}
            {/*                    firstname: personValue.firstname,*/}
            {/*                    tel: personValue.tel,*/}
            {/*                    dossierName: personValue.dossierName,*/}
            {/*                    verificationChoice: 'PVID'*/}
            {/*                }*/}
            {/*                createProject(obj).then((value: any) => {*/}
            {/*                    setHaveToFetch('fetch')*/}
            {/*                    localStorage.setItem('caseID', value.data.dossierId)*/}
            {/*                    console.log(value)*/}
            {/*                    localStorage.removeItem('location')*/}
            {/*                    router("/pvid");*/}
            {/*                })*/}

            {/*            }}*/}
            {/*            disabled={handleDisable()}*/}
            {/*        >*/}
            {/*            {t("case-5")}*/}
            {/*        </Button>*/}
            {/*        <Button*/}
            {/*            sx={{...btnStyleContain}}*/}
            {/*            variant={"contained"}*/}
            {/*            fullWidth={true}*/}
            {/*            onClick={() => {*/}
            {/*                setHaveToFetch('fetch')*/}
            {/*                console.log(haveToFetch)*/}
            {/*            }}*/}
            {/*            disabled={handleDisable()}*/}
            {/*        >*/}
            {/*            {t("case-4")}*/}
            {/*        </Button>*/}
            {/*        <Button sx={btnStyleContain} variant={"contained"} fullWidth={true}*/}
            {/*                disabled={handleDisable()}*/}
            {/*                onClick={() => {*/}
            {/*                    const obj = {*/}
            {/*                        firstname: personValue.firstname,*/}
            {/*                        tel: personValue.tel,*/}
            {/*                        dossierName: personValue.dossierName,*/}
            {/*                        verificationChoice: 'AUTH_DOCUMENT'*/}
            {/*                    }*/}
            {/*                    createProject(obj).then((value: any) => {*/}
            {/*                        setHaveToFetch('fetch')*/}
            {/*                        localStorage.setItem('caseID', value.data.dossierId)*/}
            {/*                        console.log(value)*/}
            {/*                        localStorage.removeItem('location')*/}
            {/*                        router("/document-authenticity");*/}
            {/*                    }).catch((error) => {*/}
            {/*                        console.log(error)*/}
            {/*                    })*/}
            {/*                    setHaveToFetch('jssss')*/}
            {/*                }}*/}
            {/*        >*/}
            {/*            {t("case-3")}*/}
            {/*        </Button>*/}
            {/*        <Button sx={btnStyleContain} variant={"contained"} fullWidth={true}*/}
            {/*                disabled={handleDisable()}*/}
            {/*                onClick={() => {*/}
            {/*                    // const obj = {*/}
            {/*                    //     firstname: personValue.firstname,*/}
            {/*                    //     tel: personValue.tel,*/}
            {/*                    //     dossierName: personValue.dossierName,*/}
            {/*                    //     verificationChoice: 'CLASSIC_DOCUMENT_SELFIE'*/}
            {/*                    // }*/}
            {/*                    // createProject(obj).then((value: any) => {*/}
            {/*                    //     setHaveToFetch('fetch')*/}
            {/*                    //     localStorage.setItem('caseID', value.data.dossierId)*/}
            {/*                    //     console.log(value)*/}
            {/*                    //     localStorage.removeItem('location')*/}
            {/*                    //     router("/classic-selfie");*/}
            {/*                    // }).catch((error) => {*/}
            {/*                    //     console.log(error)*/}
            {/*                    // })*/}
            {/*                }}*/}
            {/*        >*/}
            {/*            {t("case-2")}*/}
            {/*        </Button>*/}
            {/*        <Button*/}
            {/*            sx={btnStyleContain}*/}
            {/*            variant={"contained"}*/}
            {/*            fullWidth={true}*/}
            {/*            disabled={handleDisable()}*/}
            {/*            onClick={() => {*/}
            {/*                const obj = {*/}
            {/*                    firstname: personValue.firstname,*/}
            {/*                    tel: personValue.tel,*/}
            {/*                    dossierName: personValue.dossierName,*/}
            {/*                    verificationChoice: 'CLASSIC_DOCUMENT'*/}
            {/*                }*/}
            {/*                createProject(obj).then((value: any) => {*/}
            {/*                    setHaveToFetch('fetch')*/}
            {/*                    localStorage.setItem('caseID', value.data.dossierId)*/}
            {/*                    console.log(value)*/}
            {/*                    localStorage.removeItem('location')*/}
            {/*                    router("/classic");*/}
            {/*                })*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {t("case-1")}*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}
