import React from "react";
import {Box} from "@mui/material";
import Subtitle from "../Text/Subtitle";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../AppContext";

interface CardProps {
    src: string;
    alt: string;
    firstText: string;
    secondText: string;
    onClick: () => void;
}
export default function GridCard({src, alt, firstText, secondText, onClick}: CardProps): React.JSX.Element {
    const { t } = useTranslation();
    const { primaryColor} = useGlobalContext();

    return (
        <Box
            onClick={onClick}
            sx={{
                boxShadow: '3px 2px 8px 1px #0000001A inset',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '140px',
                cursor: 'pointer'
        }}>
            <img style={{ width: '40px', marginBottom: '10px', color: primaryColor}} alt={alt} src={src}/>
            <Subtitle text={firstText}/>
            <Subtitle text={secondText}/>
        </Box>
    );
}