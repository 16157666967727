import axios from "axios";
import {envConfig} from "./allEndpoint";

export async function createProject(form: any) {
    return await axios.post(envConfig.createProject, form, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export async function getCaseID(value: string) {
    return await axios.get(envConfig.getProjectIDAndFlowID + "?dossierId=" + value);
}

export async function callMailOpen(dossierId: string, flowId: string) {
    return await axios.post(envConfig.getMailOpen + "?dossierId=" + dossierId + "&flowId=" + flowId, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export async function getPersonValue() {
    const response = await getCaseID(localStorage.getItem("caseID") ?? "").catch((err) => {
        console.log(err);
    })
    return response?.data
}

export async function sendSMS(form: any) {
    return await axios.post(envConfig.sendSMS, form, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export async function validateSMS(form: any) {
    return await axios.post(envConfig.validateSMS, form, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export async function confirmAgreement(userID: string) {
    return await axios.put(envConfig.confirmAgreement + userID, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export function generateRandomString(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export async function postUUid(uuuID: string) {
    if (localStorage.getItem("caseID")) {
        const caseID = localStorage.getItem("caseID")
        const flowId = localStorage.getItem("flowId")
        return await axios.patch(envConfig.postUUID + "?dossierId=" + caseID + "&uuid=" + uuuID + "&flowId=" + flowId, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}
export async function uploadUserConsent(obj:object){
    return await axios.post(envConfig.projectUserConsent ,obj, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}
