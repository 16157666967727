import {ToastContainer} from "react-toastify";
import {useEffect, useState} from "react";

export default function ProjectToastContainer(){
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        // Callback function to update the screen width state
        const updateScreenWidth = (): void => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener for screen resize
        window.addEventListener('resize', updateScreenWidth);

        // Remove event listener on component unmount
        return (): void => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);
    return(
    <ToastContainer
        position="top-right"
        autoClose={2500}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        toastStyle={{
            ...(screenWidth < 600 && {
                width:'50%',
                marginLeft:'50%',
                fontSize:'15px',
            })
        }}
    />
    )
}