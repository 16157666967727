import React from "react";
import {Button} from "@mui/material";

interface ContainedButtonProps {
    text: string;
    onClick: () => void;
    customSx?: React.CSSProperties
}

export default function OutlinedButton({text, onClick, customSx}: ContainedButtonProps) : React.JSX.Element {

    return (
        <Button
            sx={{
                border: '1px solid black',
                color: '#000000',
                backgroundColor: 'none',
                textTransform: 'none',
                borderRadius: '6px',
                padding: '16px 32px 16px 32px',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                ...customSx,
                '&:hover': {
                    backgroundColor: 'white',
                    border: '1px solid black',
                }}}
            variant="outlined"
            fullWidth={true}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}