
import {Backdrop, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ReactNode} from "react";
import "../component/componentFile.css"
interface Props{
    children: ReactNode,
    open:boolean
}
export default function PopupModelV2(props:Props){
    return(
        <>
            <Backdrop
                open={props.open}
            >
                <div className={'flexColumn_jc_ac containerModelV3'} style={{justifyContent:'space-between',alignItems:'center',background:'white'}}>
                    <div style={{alignItems:'flex-end',width:'100%'}} className={'flexColumn_jc_ac'} >
                        <IconButton aria-label="delete" color={'primary'}>
                            <CloseIcon color={'primary'} sx={{width:'30px'}}/>
                        </IconButton>
                    </div>
                    <div className={'containerBodyV3 flexColumn_jc_ac'} >
                        {props.children}
                    </div>
                </div>
            </Backdrop>

        </>
    )
}