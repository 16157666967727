import React from "react";
import {Button} from "@mui/material";

interface ContainedButtonProps{
    text: string;
    onClick: () => void;
    customSx?: React.CSSProperties
}

export default function ContainedButton({text, onClick, customSx}: ContainedButtonProps) : React.JSX.Element {

    return (
        <Button
            sx={{
                borderRadius: '6px',
                padding: '16px 32px 16px 32px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                ...customSx,
            }}
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}