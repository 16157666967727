import React from "react";
import CommonImage from "../Photo/CommonImage";
import remove from "../../asset/remove.png";
import Subtitle from "../Text/Subtitle";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";

interface RemoveButtonProps {
    onClick: () => void;
}

export default function RemoveButton({onClick} : RemoveButtonProps) : React.JSX.Element {
    const { t } = useTranslation();
    return (
        <Button onClick={onClick} sx={{display: 'flex', paddingLeft: 0, textTransform: 'none'}}>
            <CommonImage style={{width: '16px'}} alt="remove" src={remove}/>
            <Subtitle customSx={{fontWeight: 600, fontSize: '11px', color: '#CE0500', marginLeft: '6px'}}
                      text={t('remove')}/>
        </Button>
    );
}