import certigna from "../../asset/logo.png";
import {Button, Stack} from "@mui/material";
import * as React from "react";
import Fade from "@mui/material/Fade";
import {btnStyleContain} from "../../component/ButtonStyleComponent";
import {useTranslation} from "react-i18next";
import logo from "../../asset/backGroundimg.png"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

export default function LastPage() {
    const {t} = useTranslation();
    const router = useNavigate()
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Fade in={true}>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    width={'100%'} height={'100vh'} bgcolor={'#f3f5fc'}>
                    <Stack width={'100%'}
                           direction={'row'}
                           alignItems={'center'}
                           paddingLeft={'3%'}
                           bgcolor={'white'} height={'6%'}>
                        <img src={certigna} className={'img-size'} alt={'logo'}/>
                    </Stack>
                    <Stack width={'100%'} height={'94%'} direction={'column'} justifyContent={width < 768 ? 'space-between' : 'center'}
                           alignItems={'center'}
                           rowGap={3}
                    >
                        {width < 768 &&
                            <div>
                        </div>}
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            width={'70%'}
                            maxWidth={'407px'}
                        >
                            <img src={logo} style={{marginBottom: '10px', maxWidth: '300px'}} alt={'logo'}/>
                            <div className={'titleText'} style={{textAlign: 'center', fontFamily: 'poppins',fontWeight: 600, color: '#000000'}}>
                                {t("error-1")}
                            </div>
                            <div className={'contentText'} style={{color: "#525050",fontWeight:400, fontFamily: 'poppins',textAlign:'center'}}>
                                {t("error-2")}
                            </div>

                        </Stack>
                        <Stack width={'80%'} paddingBottom={5}
                               maxWidth={'407px'}
                        >
                            <Button variant={'contained'} sx={btnStyleContain} fullWidth={true} onClick={() => {
                                router('/blank-page')
                            }}>Fermer</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Fade>
        </>
    )
}