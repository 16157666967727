import React from "react";
import {Box} from "@mui/material";
import {styled} from "@mui/system";

interface FlexCardProps {
    marginTop: React.CSSProperties;
    marginBottom: React.CSSProperties;
}

export const FlexCard = styled(Box)<FlexCardProps>(({marginTop, marginBottom}) => ({
    // boxShadow: '0px 0px 11.220779418945312px 0px #0000001A',
    boxShadow: '3px 2px 8px 1px #0000001A inset',
    // box-shadow: -6px -1px 8px 1px #FFFFFF inset;
    // box-shadow: 3px 2px 8px 1px #0000001A inset;
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px 20px 16px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start ',
    width: '100%',
}))

export const InnerCard = styled(Box)<FlexCardProps>(({marginTop, marginBottom}) => ({
    boxShadow: '3px 2px 8px 1px #0000001A inset',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px 20px 16px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start ',
    width: '100%',
}))