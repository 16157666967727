import React from "react";
import {Box, Typography} from "@mui/material";
import Subtitle from "../../component/Text/Subtitle";

export default function Blank() {
    return (
        <Box sx={{
            height: '100dvh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Subtitle customSx={{fontSize: '20px'}} text={'End Process'}/>
        </Box>
    );
}