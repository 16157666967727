import React, {ReactNode} from "react";
import {Box} from "@mui/material";
import {styled} from "@mui/system";
import ResponsiveImage from "../Photo/ResponsiveImage";
import logo from "../../asset/logo.png";

interface TemplateProps {
    child: ReactNode;
    customSx?: React.CSSProperties;
}

export default function Template({child, customSx}: TemplateProps): React.JSX.Element {
    return (
        <Box sx={{backgroundColor: '#f2f5fc', minHeight: '100dvh', position: 'relative'}}>
            <ResponsiveImage src={logo} alt={'logo'}/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // backgroundColor: '#f2f5fc',
                // backgroundColor: '#ffffff',
                height: '100dvh',
                width: '100%',
                px: '20px',
                position: 'relative',
                flexGrow: 1,
                ...customSx
            }}>
                {child}
            </Box>
        </Box>
    );
}