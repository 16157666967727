import React from "react";
import { styled } from '@mui/system';
import {Typography} from "@mui/material";

interface SubtitleProps {
    text: string;
    customSx?: React.CSSProperties
}

const Text = styled(Typography)({
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#000000'
    // overflow: 'hidden',        // Hides overflow
    // textOverflow: 'ellipsis', // Adds ellipsis (...) for overflow
    // whiteSpace: 'nowrap',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexWrap: 'wrap',
})

export default function Subtitle({ text, customSx, ...props }: SubtitleProps) : React.JSX.Element {
    return <Text sx={{...customSx}} {...props}>{text}</Text>;
}