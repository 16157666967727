import {ShareID} from "@shareid/onboarding_desktop";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../component/AppContext";

import {toast} from "react-toastify";
import {postUUid} from "../../service/createProject";
interface Props {
    token: string;
    externalID: string;
    documentType?: "all" | "document" | "liveness";
}
export default function ShareIDProcess(props: Props){
    const router = useNavigate()
    const {primaryColor,setShouldFetch,shouldFetch} =useGlobalContext()
    return(
        <>
            <ShareID
                accessToken={props.token}
                language={"fr"}
                environment={"sandbox"}
                debug={true}
                onFail={({ external_id, message, code }) => {
                    console.log(code + "/" + message)
                    console.log(external_id)
                   if (code === 3 && message ==='There is no document found'){
                       toast.warning("Share ID erreur Réessai")
                       setTimeout(() => {
                          setShouldFetch(!shouldFetch)
                       }, 3500);
                   }
                }}
                onSuccess={ ({ external_id, message, uuid, }) => {

                    console.log(external_id)
                    console.log(message)
                    postUUid(uuid).catch((err) =>{
                        console.log(err)
                    })
                    router("/success")
                }}
                metadata={{
                    external_id: JSON.stringify({
                        external_id: props.externalID,
                        flow_id: localStorage.getItem('flowId') ?? "flowIdXXXX01",
                        dossier_id: localStorage.getItem('caseID') ?? "dossierIdXXXX01",
                    })
                }}
                theme={{
                    colors: {
                        primary: {
                            bg: "transparent",
                            active:primaryColor,
                            hover:primaryColor,

                        },

                    },
                    text: {
                        title: {
                            size: "15px",
                            primaryColor:primaryColor
                        },
                        subtitle: {
                            size: "12px",

                        },
                        paragraph: {
                            size: "12px",
                        },

                    },
                }}
                verificationStep={props.documentType}
            />
        </>
    )
}