import React from "react";
import Template from "../../component/Layout/Template";
import {Box, Button, CircularProgress} from "@mui/material";
import Title from "../../component/Text/Title";
import {useTranslation} from "react-i18next";
import Subtitle from "../../component/Text/Subtitle";
import {useGlobalContext} from "../../component/AppContext";
import ContainedButton from "../../component/Button/ContainedButton";
import OutlinedButton from "../../component/Button/OutlinedButton";
import CommonImage from "../../component/Photo/CommonImage";
import refuse from "../../asset/refuse.png";
import valid from "../../asset/valid.png";
import {useNavigate} from "react-router-dom";

export default function Valid() {
    const {t} = useTranslation();
    const {primaryColor} = useGlobalContext();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/blank');
    };

    return (
        <Template child={
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                minHeight: '100%'
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <CommonImage src={valid} alt={valid} style={{width: '270.94px', marginBottom: '30px'}}/>
                    <Title text={t('validate-status', {status: 'validée'})}/>
                    <Subtitle customSx={{marginTop: '10px', marginBottom: '3px', color: '#525050'}}
                              text={t('validate-description', {status: 'validée'})}/>
                    <Subtitle customSx={{marginBottom: '3px', color: '#525050'}} text={t('validate-subtitle-valid-1')}/>
                    <Subtitle customSx={{marginBottom: '10px', color: '#525050'}}
                              text={t('validate-subtitle-valid-2')}/>
                </Box>
                <ContainedButton customSx={{marginBottom: '20px'}} text={t('fermer')} onClick={handleClick}/>
            </Box>
        }/>
    );
}