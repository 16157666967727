export const btnStyleContain = {
    "&.MuiButton-contained.Mui-disabled": {
        color: 'white',
        background: 'lightgray',
    },
    "&.MuiButton-contained": {
        fontFamily: 'Poppins',
        textTransform: 'none',
        height: '48px',
        borderRadius:'6px',
        fontWeight:600,
        fontSize:'16px'
    },
}
export const btnStyleContainNoHeight = {
    "&.MuiButton-contained.Mui-disabled": {
        color: 'white',
        background: 'lightgray',
    },
    "&.MuiButton-contained": {
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius:'6px',
        fontWeight:600,
        fontSize:'16px'
    }
}
export const btnStyleOutLine = {
    "&.MuiButton-outlined":{
        border:'1px solid black',
        fontFamily: 'Poppins',
        textTransform: 'none',
        height: '48px',
        borderRadius:'6px',
        background:'black',
        color:'white',
        fontWeight:600,
        fontSize:'16px'
    },
    "&.MuiButton-outlined.Mui-disabled": {
        color: 'lightgray',
        borderColor: 'lightgray',
    },
}