import {Button, Stack} from "@mui/material";
import {CSSProperties, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {btnStyleContain} from "../../component/ButtonStyleComponent";
import {useTranslation} from "react-i18next";
import '../pvid/verification.css'
import {confirmAgreement} from "../../service/createProject";
import VerifyPhoneNumber from "../optProcess/VerifyPhoneNumber";
import {useGlobalContext} from "../../component/AppContext";

interface Props {
    textArray: string[],
    imgAndWidthArray: { width: string, img: string }[],
    isConfirm: boolean,
    setConfirm: Dispatch<SetStateAction<boolean>>,
    onSuccessRouteTo: string
}

export default function PageConfirmContent({
                                               isConfirm,
                                               setConfirm,
                                               imgAndWidthArray,
                                               textArray,
                                               onSuccessRouteTo
                                           }: Props) {
    const {t} = useTranslation()
    const {personValue} = useGlobalContext()
    useEffect(() => {
        if (personValue.currentStep === "OTP" || personValue.currentStep === "PHONE") {
            setConfirm(true)
        } else {
            setConfirm(false)
        }
    }, [])
    return (
        <>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                marginTop={1}
                spacing={1}
                width={'100%'}
                style={{position: 'relative'}}
                height={'50%'}
            >
                <span className={'subTitle'} style={{
                    fontWeight: '600',
                    marginTop: '5px'
                }}>Vérification en {imgAndWidthArray.length} étapes :</span>
                <MainContainer>
                    {imgAndWidthArray.map((value, index) => {
                        return (

                            <BoxContainer key={index}>
                                <img src={value.img} style={{width: value.width}} alt={'logo img'}/>
                                <TextLabel>{textArray[index]}</TextLabel>
                            </BoxContainer>

                        )
                    })}
                </MainContainer>
                <div className={'flexColumn_jc_ac'} style={containerButton}>
                    <Button variant={'contained'} sx={btnStyleContain} style={{width: '30%'}} onClick={() => {
                        confirmAgreement(localStorage.getItem('caseID') ?? "").then(() => {
                            setConfirm(true)
                        }).catch((err) => {
                            console.log(err)
                        })
                    }}> {t("commencer")}</Button>
                </div>
            </Stack>
            {isConfirm && <VerifyPhoneNumber onSuccess={onSuccessRouteTo}/>}
        </>
    )
}
const containerButton: CSSProperties = {
    position: 'fixed',
    bottom: '-1px',
    boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.15)',
    padding: '16px 24px 16px 24px',
    width: '1200px',
    background: 'white'
}
const TextLabel = (props: PropsWithChildren) => {
    return (
        <span style={{width: '80%'}} className={'contentText'}>{props.children}</span>
    )
}
const BoxContainer = (props: PropsWithChildren) => {
    return (
        <>
            <div style={{
                width: '100%',
                borderRadius: '5px',
                background: 'rgba(255, 255, 255, 1)',
                padding: '20px',
                gap: '10px',
                maxWidth: '327px',
                boxShadow: '-6px -1px 8px 1px rgba(255, 255, 255, 1) inset, 3px 2px 8px 1px rgba(0, 0, 0, 0.1) inset'

            }}
                 className={'flexColumn_jc_ac'}>
                {props.children}
            </div>
        </>
    )
}
const MainContainer = (props: PropsWithChildren) => {
    return (
        <>
            <div className={'noResponsiveContainer'}>
                {props.children}
            </div>

        </>
    )
}