import React from "react";
import {styled} from '@mui/system';

interface TitleProps {
    text: string;
    customSx?: React.CSSProperties
}

const Text = styled('h3')({
    fontWeight: 600,
    fontSize: '22px',
    fontFamily: 'Poppins'
})

export default function Title({text, customSx, ...props}: TitleProps): React.JSX.Element {
    return <Text sx={{...customSx}} {...props}>{text}</Text>;
}