import React from "react";
import CommonImage from "../Photo/CommonImage";
import success from "../../asset/success.png";
import Title from "../Text/Title";
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function SuccessButton() {
    const { t } = useTranslation()

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#197B4A', padding: '2px 8px 2px 8px', borderRadius: '200px'}}>
            <CommonImage style={{width: '12px'}} alt="success" src={success}/>
            <Title customSx={{marginLeft: '8px', fontSize: '8px', fontWeight: 700, color: 'white', letterSpacing: '0.3px'}} text={t('success').toUpperCase()}/>
        </Box>
    );
}