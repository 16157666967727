import React from "react";

interface CommonImageProps {
    src: string,
    alt: string,
    style: React.CSSProperties
}

export default function CommonImage({src, alt, style}: CommonImageProps) : React.JSX.Element {
    return (
        <img style={style} alt={alt} src={src}/>
    );
}