import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import AppContext from "./component/AppContext";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      // en: { translation: require("./i18n/locales/en.json") },
      fr: { translation: require("./i18n/locales/fr.json") },
    },
    lng: "fr", 
    interpolation: { escapeValue: false },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <AppContext>
  <I18nextProvider i18n={i18n}>
      <App />
  </I18nextProvider>
    </AppContext>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
