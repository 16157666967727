import { Button, CircularProgress, Stack} from "@mui/material";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {MuiOtpInput} from "mui-one-time-password-input";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {btnStyleContain} from "../../component/ButtonStyleComponent";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../component/AppContext";
import {generateRandomString, sendSMS, validateSMS} from "../../service/createProject";
import FailProcess from "./FailProcess";
import * as React from "react";
import PopupModelV2 from "../../component/PopupModelV2";
interface OtpProps{
    onSuccess:string;
}

export default function VerifyOTP({onSuccess}:OtpProps) {
    const [open, setOpen] = useState(true);
    const [opt, setOpt] = useState("");
    const router = useNavigate()
    const [showError, setShowError] = useState(false);
    const [retryOut, setRetryOut] = useState(false)
    const [retry, setRetry] = useState(parseInt(localStorage.getItem('otp') ?? "3"))
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const {personValue, setHaveToFetch, primaryColor} = useGlobalContext()
    const attempt = (value: number) => {
        if (value === 1) {
            setRetry(2)
            setShowError(true)
        } else if (value === 2) {
            setRetry(1)
            setShowError(true)
        } else if (value === 3 && personValue.retryAttempts === 2) {
            setOpen(false)
        } else if (value === 3) {
            setShowError(true)
            setRetryOut(true)
            setRetry(0)
        } else {
            setShowError(false)
            setRetryOut(false)
        }
    }
    const disableBtn = () => {
        return opt.replace(/\D/g, "").length < 6 || retryOut || loading;
    }
    useEffect(() => {
        console.log(personValue)
        attempt(personValue.otpAttempts ?? 3)
        if (personValue.retryAttempts === 2) {
            setRetryOut(false)
        }
        if (personValue.status === 'BANNED' && open) {
            router('/page-block')
        }
        localStorage.removeItem('sharedid')
    }, [setRetryOut, personValue])

    function validateOpt() {
        const obj = {
            dossierId: localStorage.getItem('caseID') ?? "",
            otp: opt
        }
        validateSMS(obj).then((value: any) => {
            setLoading(false)
            console.log(value)
            const id = generateRandomString()
            setHaveToFetch(id)
            console.log(personValue)
            localStorage.setItem('sharedid', value.data.payload.access_token)
            localStorage.setItem('externalId', value.data.trace)
            router(onSuccess)
        }).catch((err: any) => {
            setLoading(false)
            setOpt('')
            const random = generateRandomString()
            setHaveToFetch(random)
            if (err.response.data.subErrors) {
                attempt(err.response.data.subErrors[0].attempts)
            }
            if(err.response.status === 403){
                setOpen(false)
            }
            if(err.response.status === 410){
                router('/page-block')
            }
            setShowError(true)
            console.log(err)
            document.getElementsByClassName('MuiOtpInput-TextField-1').item(0)?.getElementsByTagName("input").item(0)?.focus()
        })
    }

    return (
        <>
            <PopupModelV2 open={open}>
                <div className={'flexColumn_jc_ac'} style={{width: '100%', gap: '10px'}}>
                    <div>
                        <div style={{fontWeight: 600}} className={'subTitle'}>{t("popup_text4")}</div>
                        <div style={{fontWeight: 600}} className={'subTitle'}>{t("popup_text5")}</div>
                    </div>
                    <div style={{textAlign: 'center',width: '90%'}} className={'contentText'}>
                        <span>{t("case23")}</span>
                        <span>{t("case24")}<span style={{fontWeight: 600}}> {personValue.tel}</span></span>
                    </div>
                    <div className={'flexColumn_jc_ac'} style={{gap: '20px', paddingTop: "20px", width: '90%'}}>
                        <Stack width={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                            <div>
                                <Otp6Characters setOptValue={setOpt} disableOpt={retryOut} optValue={opt}/>
                                {showError && <div style={{width: '100%', textAlign: 'start'}}><span
                                    style={{
                                        color: 'red',
                                        fontWeight: 300,
                                        fontSize: '12px'
                                    }}>
                                {retryOut ? <span style={{fontSize:'10px'}}>{t("outAttemptOpt")} </span> :
                                    <span style={{fontSize:'11px'}}>
                                        {retry === 2 ? <span>{t("errorAttempts", {value: retry})}</span>
                                            : <span>{t("errorAttempt", {value: retry})}</span>}
                                    </span>}
                            </span>
                                </div>}
                            </div>
                        </Stack>
                        <Stack sx={{m: 1, position: 'relative'}} direction={'row'} alignItems={'center'}
                               justifyContent={'center'} width={'100%'}>
                            <Button
                                variant={'contained'}
                                fullWidth={true}
                                disabled={disableBtn()}
                                sx={btnStyleContain}
                                style={{
                                    minWidth:'280px',
                                    maxWidth:'283px'
                                }}
                                onClick={() => {
                                setLoading(true)
                                validateOpt()
                            }}>{t("valider")}</Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: `${primaryColor}`,
                                        position: 'absolute',
                                    }}
                                />
                            )}
                        </Stack>
                        <FooterText/>
                    </div>
                </div>
            </PopupModelV2>
            {!open && <FailProcess/>}
        </>
    )
}

const FooterText = () => {
    const {t} = useTranslation()
    const [isDisabled, setIsDisabled] = useState(false);
    const [count, setCount] = useState(20);
    const {personValue, setHaveToFetch} = useGlobalContext()


    useEffect(() => {
        if (personValue.retryAttempts === 2) {
            setCount(0)
        }
        if (count > 0) {
            const timer = setTimeout(() => {
                setCount(count - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setIsDisabled(false);
        }
    }, [count]);
    useEffect(() => {
        if (personValue.retryAttempts !== 2) {
            setIsDisabled(true)
        }

    }, [])
    const startCountdown = (): void => {
        setIsDisabled(true);
        requestOpt();
    };

    const requestOpt = () => {
        const obj = {
            dossierId: localStorage.getItem('caseID'),
            lastFourDigits: personValue.tel?.slice(-4)
        }
        sendSMS(obj).then((value: any) => {
            console.log(value)
            const random = generateRandomString()
            setHaveToFetch(random)
        }).catch((err) => {
            const random = generateRandomString()
            setHaveToFetch(random)
            setHaveToFetch('fetch')
            console.log(err)
        })
    }
    const disableBtn = () => {
        if (personValue.retryAttempts === 2) {
            return true
        } else {
            return isDisabled
        }
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '14px'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4}}>
                    <InfoOutlinedIcon sx={{color: 'rgba(214, 5, 106, 1)'}}/>
                    <span className={'contentText'}>{t("Code_not_received")}</span>
                </div>

                <Button variant={'text'} sx={{color: 'black', textTransform: 'none', fontSize: '14px'}}
                        disabled={disableBtn()}
                        onClick={startCountdown}>
                    <span style={footerTextStyle} className={'contentText'}>
                          {count > 0 ? <span>{t("requestOptInSec", {value: count})}</span> :
                              <span>{t("otpRequest")}</span>}
                    </span>
                </Button>


            </div>
        </>
    )
}
const footerTextStyle = {
    fontWeight: 700,
    textDecoration: 'underline',
    letterSpacing: '1px',
}

interface Props {
    setOptValue?: Dispatch<SetStateAction<string>>,
    disableOpt?: boolean,
    setDisableOpt?: Dispatch<SetStateAction<boolean>>,
    optValue?: string
}

const Otp6Characters = ({setOptValue, disableOpt, optValue}: Props) => {
    const handleChange = (newValue: any) => {
        if (setOptValue) {
            setOptValue(newValue)
        }
    };
    const StyleForInput = {
        "& .MuiInputBase-root": {
            width: "40px",
            height: "55px",
            background: 'white',
            borderRadius: "5px",
        },
        "& .kpxc.kpxc-totp-icon": {
            background: "red",
        },

    };
    useEffect(() => {
        document.getElementsByClassName('MuiOtpInput-TextField-1').item(0)?.getElementsByTagName("input").item(0)?.focus()
    }, [])
    return (
        <>
            <MuiOtpInput
                onKeyPress={(e: any) => {
                    if (!/[0-9]/.test(e.nativeEvent.key)) {
                        return e.preventDefault();
                    }
                }}
                inputMode={'numeric'}
                TextFieldsProps={{disabled: disableOpt, placeholder: '_',inputProps:{inputMode:'numeric',pattern:'[0-9]*'}}}
                gap={0.5}
                value={optValue}
                onChange={handleChange}
                length={6}
                autoFocus={true}
                sx={StyleForInput}
                validateChar={(val) => !isNaN(Number(val))}
            />
        </>
    )
}



