import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined';
import Webcam from "react-webcam";
import { useNavigate } from 'react-router-dom';
import {useGlobalContext} from "../AppContext";

function PhotoRecto() {
    const navigate = useNavigate();
    const webcamRef = useRef<Webcam>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
    const [cameraError, setCameraError] = useState<string | null>(null);
    const {option, setFrontFile, setBackFile} = useGlobalContext();

    useEffect(() => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setCameraError('Camera not available on this device.');
        }
    }, []);

    useEffect(() => {
        console.log(option)
    }, [option]);


    // const [imageRotation, setImageRotation] = useState<number>(0);
    //
    // useEffect(() => {
    //     if (capturedImage) {
    //         // console.log("Image data:", capturedImage);
    //         const img : any = new Image();
    //         img.src = capturedImage;
    //         img.onload = function () {
    //             console.log("Image loaded:", img);
    //             const that = this;
    //             EXIF.getData(img, function () {
    //                 const orientation = EXIF.getTag(that, 'Orientation');
    //                 console.log('Image orientation:', orientation);
    //                 const rotation = getRotationDegrees(orientation);
    //                 setImageRotation(rotation);
    //             });
    //         };
    //         img.onerror = function () {
    //             console.log("Error loading image:", img);
    //         };
    //     }
    // }, [capturedImage]);
    //
    // function getRotationDegrees(orientation: number | undefined): number {
    //     switch (orientation) {
    //         case 3:
    //             return 180;
    //         case 6:
    //             return 90;
    //         case 8:
    //             return -90;
    //         default:
    //             return 0;
    //     }
    // }

    const capture = () => {
        try {
            const imageSrc = webcamRef.current?.getScreenshot() || '';
            setCapturedImage(imageSrc);

        } catch (error) {
            console.error('Error capturing image:', error);
        }
    };

    const retake = () => {
        setCapturedImage(null);
    };

    function generateDynamicFilename() {
        const timestamp = new Date().getTime();
        return `file-${timestamp}.png`;
    }

    const dynamicFilename = generateDynamicFilename();

    const saveToDatabase = () => {
        if (capturedImage) {
            const blob = dataUriToBlob(capturedImage);
            const imageFile = blobToImageFile(blob, dynamicFilename);


            if (sessionStorage.getItem('option') === 'front') {
                setFrontFile(imageFile);
                // sessionStorage.setItem("frontFile", imageFile);
                sessionStorage.setItem("selectedFrontFileName", imageFile.name);
            } else if (sessionStorage.getItem('option')=== 'back') {
                setBackFile(imageFile);
                sessionStorage.setItem("selectedBackFileName", imageFile.name);
            }
            navigate(-1);
        }
    };

    const dataUriToBlob = (dataUri: any) => {
        const byteString = atob(dataUri.split(',')[1]);
        const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([uint8Array], { type: mimeString });
    };

    const blobToImageFile = (blob: any, fileName: any) => {
        return new File([blob], fileName, { type: blob.type });
    };

    const switchCamera = () => {
        setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
    };

    const videoConstraints = {
        facingMode: facingMode
    };

    const containerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: 'black',
    };

    const webcamContainerStyle: CSSProperties = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        transform: facingMode === 'user' ? 'scaleX(-1)' : 'none',
    };

    const arrowBackIconStyle: CSSProperties = {
        position: 'absolute',
        top: '30px',
        left: '20px',
        color: 'white',
        cursor: 'pointer',
    };

    const circularButtonStyle: CSSProperties = {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        border: '2px solid #fff',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0
    };

    const innerCircleStyle: CSSProperties = {
        width: '46px',
        height: '46px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: '4px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const switchCameraButtonStyle: CSSProperties = {
        position: 'fixed',
        bottom: '23px',
        right: '20px',
        borderRadius: '50%',
        border: '2px solid #333',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    };

    const iconStyle: CSSProperties = {
        fontSize: '24px',
    };

    return (
        <div style={containerStyle}>
            <div style={arrowBackIconStyle}>
                {!capturedImage ? (
                    <ArrowBackIosNewIcon onClick={() => {navigate(-1);}} />
                ) : (
                    <></>
                )}
            </div>
            <div style={webcamContainerStyle}>
                {!capturedImage ? (
                    <Webcam
                        style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                ) : (
                    <img
                        id="capturedImage"
                        src={capturedImage}
                        alt="Captured"
                        // style={{ transform: `rotate(${imageRotation}deg)` }}
                    />
                )}
            </div>
            {!capturedImage ? (
                <>
                    <button style={circularButtonStyle} onClick={capture}>
                        <span style={iconStyle}></span>
                        <div style={innerCircleStyle}></div> {/* Inner Circle */}
                    </button>
                    <button
                        onClick={switchCamera}
                        style={switchCameraButtonStyle}
                    >
                        <CameraswitchOutlinedIcon style={{ fontSize: 30, color: 'white' }} />
                    </button></>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px'}}>
                    <button
                        onClick={retake}
                        style={{
                            border: 'none',
                            background: '#FF5252',
                            borderRadius: '50%',
                            width: '48px',
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            outline: 'none',
                            cursor: 'pointer',
                            marginRight: '60px',
                        }}
                    >
                        <CloseIcon style={{ fontSize: 24, color: 'white' }} />
                    </button>
                    <button
                        onClick={saveToDatabase}
                        style={{
                            border: 'none',
                            background: '#4CAF50',
                            borderRadius: '50%',
                            width: '48px',
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            outline: 'none',
                            cursor: 'pointer',
                            marginLeft: '70px',
                        }}
                    >
                        <CheckIcon style={{ fontSize: 24, color: 'white' }} />
                    </button>
                </div>
            )}
        </div>
    );
}

export default PhotoRecto;