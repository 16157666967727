
import {Stack} from "@mui/material";
import certigna from "../../asset/logo.png";
import * as React from "react";

export default function PageBlock(){
    return(
        <>
            <Stack width={'100%'} height={'100vh'} direction={'column'} alignItems="center" justifyContent={'space-between'} textAlign={'center'} bgcolor={'#f3f5fc'}>
                <Stack width={'100%'}
                       direction={'row'}
                       alignItems={'center'}
                       paddingLeft={'3%'}
                       bgcolor={'white'} height={'6%'}>
                    <img src={certigna} className={'img-size'} alt={'logo'}/>
                </Stack>
                <Stack height={'94%'} direction={'row'} alignItems={'center'} padding={5}>
                    <span  style={{fontSize:'20px'}}>Veuillez contacter l'opérateur</span>
                </Stack>
            </Stack>
        </>
    )
}
