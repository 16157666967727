import React, {useState, useRef, ChangeEvent, useEffect, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import Template from "../../component/Layout/Template";
import Title from "../../component/Text/Title";
import Subtitle from "../../component/Text/Subtitle";
import {useTranslation} from "react-i18next";
import {FlexCard} from "../../component/Card/FlexCard";
import {Box, Button} from "@mui/material";
import {useGlobalContext} from "../../component/AppContext";
import camera from "../../asset/camera.png";
import selfie from "../../asset/selfie-camera.png";
import finish from "../../asset/finish.png";
import replace from "../../asset/replace.png";
import CommonButton from "../../component/Button/CommonButton";
import CommonImage from "../../component/Photo/CommonImage";
import ContainedButton from "../../component/Button/ContainedButton";
import {envConfig, IDENTITY_VERIFICATION_URL} from "../../service/allEndpoint";

export default function Selfie() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [choice, setChoice] = useState("");
    const [frontFileErrorMessage, setFrontFileErrorMessage] = useState("");

    const {
        setDocumentType,
        documentType,
        frontFile,
        backFile,
        setFrontFile,
        setBackFile,
        selfiePhoto,
        setSelfiePhoto,
    } = useGlobalContext();
    const frontFileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFrontFileName, setSelectedFrontFileName] = useState<string | null>(
        sessionStorage.getItem("selectedFrontFileName")
    );

    useEffect(() => {
    }, [frontFile, choice, documentType, selfiePhoto]);

    const uploadFrontPhoto = () => {
        sessionStorage.setItem('option', 'front');
        setChoice("upload");
        if (frontFileInputRef.current) {
            frontFileInputRef.current.click();
        }
    };

    const handleFrontFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const allowedTypes = ['.png', '.jpeg', '.jpg'];
        const selectedFile = event.target.files?.[0];
        setFrontFileErrorMessage("");
        if (selectedFile) {
            const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();
            if (!allowedTypes.includes(fileType)) {
                setFrontFileErrorMessage('Unsupported file type. Please select a valid file.');
            } else {
                setFrontFileErrorMessage('');
            }
            // setFrontFile(selectedFile);
            setSelfiePhoto(selectedFile);
            console.log(`Selected File: ${selectedFile.name}`);
            // setSelectedFrontFileName(selectedFile.name);
            // localStorage.setItem("selectedFrontFileName", selectedFile.name);
        }
    };

    const takeFrontPhoto = () => {
        sessionStorage.setItem('option', 'front');
        setChoice("take");
        navigate('/classic/capture-image');
    }

    const replaceFrontPhoto = () => {
        // localStorage.removeItem('selectedFrontFileName')
        // setSelectedFrontFileName("");
        setSelfiePhoto(null);
        setFrontFileErrorMessage("");
        if (frontFileInputRef.current) {
            frontFileInputRef.current.value = "";
        }
    };

    const verifyData = () => {
        navigate('/classic-selfie/share-id/loading');
        const formData = new FormData();
        console.log(frontFile);
        console.log(backFile);
        formData.append('documentFront', frontFile);
        if(documentType !== 'passport') {
            formData.append('documentBack', backFile);
        }

        const documentCountry = sessionStorage.getItem('documentCountry');
        if (documentCountry !== null) {
            formData.append('documentCountry', documentCountry);
        } else {
            formData.append('documentCountry', 'fr');
        }
        formData.append('documentType', documentType);
        formData.append('documentRotation', '0');
        if (window.location.href.includes('/classic-selfie')) {
            formData.append('documentSelfie', selfiePhoto);
        }

        fetch(envConfig.getProjectIDAndFlowID + '?dossierId=' + localStorage.getItem('caseID' + '&flowId=' + localStorage.getItem('flowId')) + process.env.REACT_APP_VERIFY_SHARE_ID, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // if (localStorage.getItem('success') === 'true') {
                //     setTimeout(() => {
                //         navigate('/classic-selfie/share-id/valide');
                //     }, 3000);
                // } else {
                //     if (!data.authenticity) {
                if (data.status !== 'success') {
                    setTimeout(() => {
                        navigate('/classic-selfie/share-id/refuse');
                    }, 1000);
                    sessionStorage.setItem('success', 'true');
                } else {
                    setTimeout(() => {
                        navigate('/classic-selfie/share-id/valid');
                    }, 1000);
                }
                setFrontFile(null);
                if(documentType !== 'passport') {
                    setBackFile(null);
                }
                setSelfiePhoto(null);
                setDocumentType('');
                sessionStorage.removeItem('documentCountry');

                console.log('API Response:', data);
                // }
            })
            .catch((error) => {
                navigate('/classic-selfie/share-id/refuse');
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        isMobileDevice();
    })

    const isMobileDevice = (): boolean => {
        const userAgent: string = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };

    // const videoRef = useRef<HTMLVideoElement | null>(null);
    // const [cameraAccess, setCameraAccess] = useState(true);
    //
    // const handleCapture = async () => {
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({
    //             video: { facingMode: "user" },
    //         });
    //
    //
    //         setCameraAccess(true);
    //         console.log(cameraAccess)
    //         if (videoRef.current) {
    //             videoRef.current.srcObject = stream;
    //             videoRef.current.play();
    //
    //         }
    //         //
    //     } catch (error) {
    //         console.error("Error accessing camera:", error);
    //     }
    // };

    return (
        <Template customSx={{backgroundColor: '#ffffff'}} child={
            <>
                <Title customSx={{marginTop: '90px', marginBottom: '10px', textAlign: 'center'}} text={t('selfie')}/>
                <Subtitle customSx={{color: '#525050'}} text={t('selfie-subtitle-1')}/>
                <Subtitle customSx={{marginBottom: '20px', color: '#525050'}} text={t('selfie-subtitle-2')}/>

                <FlexCard marginTop={'10px'} marginBottom={'0px'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex'}}>
                            <CommonImage style={{width: '20px'}} alt="selfie" src={selfie}/>
                            <Title customSx={{marginLeft: '10px', fontSize: '14px'}} text={t('selfie-image-title')}/>
                        </Box>
                        {selfiePhoto && (<CommonImage style={{width: '24px'}} alt="finish" src={finish}/>)}
                    </Box>
                    <input
                        type="file"
                        accept="image/*"
                        capture="user"
                        style={{display: 'none'}}
                        ref={frontFileInputRef}
                        onChange={handleFrontFileChange}
                    />
                    {/*<div>*/}
                    {/*    <button onClick={handleCapture}>Access Camera</button>*/}
                    {/*    {cameraAccess && (*/}
                    {/*        <div>*/}
                    {/*            <video ref={videoRef} style={{ maxWidth: "100%" }} />*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {selfiePhoto ? (
                        (frontFileErrorMessage !== "") ?
                            <Subtitle customSx={{
                                fontWeight: 600,
                                fontSize: '11px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                color: '#CE0500',
                            }} text={frontFileErrorMessage}/>
                            :
                            <>
                                <Subtitle customSx={{color: '#000000', marginTop: '12px', marginBottom: '15px'}}
                                          text={t('selfie-image-finish-description')}/>
                                <Box sx={{
                                    padding: "15px 35px 15px 35px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    backgroundColor: "#f2f5fc",
                                    marginBottom: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'}}>
                                    <img
                                        src={selfiePhoto instanceof File ? URL.createObjectURL(selfiePhoto) : selfiePhoto}
                                        alt={selectedFrontFileName || ""}
                                        style={{
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Box>
                            </>
                    ) : (<>
                        <Subtitle customSx={{color: '#000000', marginTop: '12px', marginBottom: '20px'}}
                                  text={t('selfie-image-description')}/>

                        <CommonButton onClick={uploadFrontPhoto} customSx={{marginBottom: '10px'}} src={camera}
                                      alt={camera}
                                      text={t('selfie-image-text')}/>
                    </>)}
                    {selfiePhoto && (
                        <>
                            <Button onClick={replaceFrontPhoto} sx={{display: 'flex', paddingLeft: 0, textTransform: 'none'}}>
                                <CommonImage style={{width: '16px'}} alt="replace" src={replace} />
                                <Subtitle customSx={{fontWeight: 600, fontSize: '11px', color: '#000000', marginLeft: '6px'}}
                                          text={t('replace')} />
                            </Button>
                        </>)}
                </FlexCard>

                {selfiePhoto && frontFileErrorMessage === ""
                    && (
                        <Box sx={{
                            display: 'flex',
                            padding: '16px 24px 16px 24px',
                            width: '100vw',
                            backgroundColor: 'white',
                            position: isMobileDevice() ? 'sticky' : 'fixed',
                            bottom: 0,
                            border: 'none',
                        }}
                        >
                            <ContainedButton
                                text={t('verify')}
                                onClick={verifyData}/>
                        </Box>
                    )}
            </>
        }/>
    );
}